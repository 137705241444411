
.bckground{
z-index: -1;
position: absolute;
top:0;

width: 100%;
}
.foundUrl{
height: 44vw;
padding-bottom: 8vw !important;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
display: flex;
align-items: flex-end;
justify-content: center;
}
.foundUrl p{
color: white;
font-size: 5vw;
}
.leaf2{
    position: absolute;
    bottom:5%;
   right: 0;
  
}
.leaf1{
    position: relative;
    top: 45px;
    
}
.leaf3{
    position: relative;
   left:28%;
}

.leaf4{
    position: absolute;
   right: 0;
top:310px;
}
.food{
  width: 100%;
 
   
}

.title{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: calc(30px + 1.5vw);
line-height: calc(30px + 4.5vw);
display: inline-block;
letter-spacing: -0.3px;
color: #331900;

}
.title2{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: calc(28px + 1.5vw);

letter-spacing: -0.3px;
color: #E47100;
margin-bottom: 3rem;
}
.example{
    position: relative;
}

form.example input[type=text] {
    padding: 10px;
    padding-right: 70px;
    font-size: 17px;
    border: transparent;
   
    width: 100%;
    text-align: right;
    background: white;font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
 
    /* identical to box height */
    
    text-align: right;
    letter-spacing: -0.3px;
    
    /* Gray 3 */
    
    color: #828282;
  }
  
  form.example button {
 
    padding: 15px 20px;
    background: #633235;
    color: white;
    font-size: 17px;
    border: none;
    border-left: none;
    cursor: pointer;
    border-radius: 8px;
    position: absolute;
  top:5px;
  left:5px;
  }
  
  form.example button:hover {
    background: #0b7dda;
  }
  
  form.example::after {
    content: "";
    clear: both;
    display: table;
  }

  .inpSearch{
    box-shadow: 0px 6px 53px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    height: 68px;
  }
  .srch{
      position: absolute;
      size: 100px;
      right:20px;
      top:12px;
  }
  .brdr2{
    border-style: none solid none none;
    border-color: #E47100;
    border-width: 14px;
    padding-right: 24px;
    margin-bottom: 3rem;
  }

  .title-2{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size:20px;

letter-spacing: -0.3px;
color: #331900;
margin-bottom: 0.5em;
}

.title2-2{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;


letter-spacing: -0.3px;
color: #828282;
margin-bottom: 0.5em;
}
.certified button{

    background: #E47100;
box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
border: none;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 16px;


letter-spacing: -0.269318px;
width: 45%;
max-width: 200px;
color: #FFFFFF;
padding: 0.8em 0;
margin-top: 1em;
}
.certified button:hover{

    background: #f28619;
}
.decoration{
    position: absolute;
    right: -50px;
 z-index: -1;
 bottom: 0px;
}
.decorationEn{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    right: auto;
    left: -50px;
}
.certifiedLogo{
    flex-direction: row;
    display: flex;
}
.certifiedLogo .div5img{
width: 50%;
}
.community{
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    background: linear-gradient(90deg, rgba(219, 9, 91, 0.19) 0%, rgba(217, 217, 217, 0) 100%);
filter: drop-shadow(-5px 3px 20px rgba(0, 0, 0, 0.1));
border-radius: 8px;
transform: matrix(-1, 0, 0, 1, 0, 0);
padding: 1em;
padding-top: 1.5em;

}
.com-text{
    transform: matrix(-1, 0, 0, 1, 0, 0); 
    text-align:initial ; 
    position: relative;
  
}

.comm-footer{
position: relative;
    bottom: -1em;
right: -1em;
height: 100px;
}
.comm-footerEn{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left:  -1em;
}
.comm-image{
    display: flex;
   align-items: center;
   justify-content: center;
}
.comm-image{
    /*transform: matrix(-1, 0, 0, 1, 0, 0); 
    display: flex;
   align-items: center;
   justify-content: center;*/
}

.com-title{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
margin-bottom: 0.2em;
letter-spacing: -0.3px;

color: #633235;
}
.community button{


    background-color: transparent ;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
letter-spacing: -0.269318px;
text-decoration-line: underline;
justify-content: end;
color: #633235;
}
.community button:hover{
    background-color: transparent ;

}

.community .arrow-button2 > .arrow2 {

    border-right: 2px solid #633235;
    border-bottom: 2px solid #633235;

}

.com-details{

    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;

margin-bottom: 0;
letter-spacing: -0.3px;

color: #633235;
}
  .thirdDiv{
      text-align: center;
      margin-top: 80px;
      padding-bottom: 80px;
      position: relative;
  }
.step2{
    height: 156px;
    margin-bottom: 60px;
}
.how{
   
font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;


letter-spacing: -0.3px;

color: #420F12;


}

.how-detail{

    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;

letter-spacing: -0.269318px;

color: #4F4F4F;
} 
.myCrsl .swiper-pagination {
position: unset !important;

}

  .thirdDiv .title{
     
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
  
    text-align: right;
    letter-spacing: -0.3px;
    color: #331900;
    display: inline-block;
   
  }
  .thirdDiv .order{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;

    text-align: right;
    letter-spacing: -0.3px;
    color: #E47100;
    display: inline-block;
   
  }
  .thirdDiv .detail{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
   
  
    letter-spacing: -0.3px;
    color: #4F4F4F;

  }
  .soon{
      position: absolute;
      top: 8px;
      z-index: 1;
      font-family: 'Cairo';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      color: #E47100;
      left: 5px;
      transform: rotate(-30deg);
  }

  .soonEn{
  
  }
  .menu{
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    background-color: white;
    position: relative;
  }
  .menueItem{
      width: 100%;
      height: 20vw;
     background-position: center;
     background-repeat: no-repeat;
     background-size: contain;
     border-radius: 14.0814px 14.0814px 0px 0px;
  }

.bckgSize{
background-size: cover;
background-repeat: no-repeat;
}
  .promotion{
    display: flex;
    align-items: center;

  }
.promotion button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
 
  
    /* identical to box height */
    
 line-height: 20px;
    letter-spacing: -0.3px;
    
    color: white;
    font-size:18px;

   
    letter-spacing: -0.3px; 
  
    padding: 10px 16px;
    
    cursor: pointer;
    border: none;
  
background-color: #E47100;
border-radius:  10px;
margin-top: 1em;
}

.promotion button:hover{
    background-color: #f38212;

}
 .certified{

    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    text-align: center;
padding: 2em;
width: 90%;
height: 300px;
display: flex;
align-items: center;
 }

  .arrow-button2 {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
 
  
    /* identical to box height */
    
 line-height: 20px;
    letter-spacing: -0.3px;
    
    color: #E47100;
    font-size:18px !important;

   
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.3px; 
  
    display: flex;
    background-color: transparent;
    padding: 10px 16px;
    transition: all .3s ease;
    cursor: pointer;
    border: none;
    width: 100%;
  
background-color: white;
border-radius: 0 0 16px 16px;
font-size: 18 !important;

}
.btnDisabled{
    color: rgb(94, 89, 89);
}

.btnDisabled > .arrow2 {
 
    border-right: 2px solid rgb(94, 89, 89) !important;
    border-bottom: 2px solid rgb(94, 89, 89) !important;
}

.arrow-button2 > .arrow2 {
    width: 10px;
    height: 10px;
    border-right: 2px solid #E47100;
    border-bottom: 2px solid #E47100;
    position: relative;
    transform: rotate(-225deg);

    transition: all .3s ease;
   margin: 0 10px;
}



 .arrow-button2:hover > .arrow2 {
    transform: rotate(-225deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.arrow-button2:hover > .arrow2::before {
    opacity: 1;
    width: 8px;
}


.menu:hover .arrow2 {
    transform: rotate(-225deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.menu:hover  .arrow2::before {
    opacity: 1;
    width: 8px;
}


.enArrow-button2> .arrow2 {
    width: 10px;
    height: 10px;
    border-right: 2px solid #E47100;
    border-bottom: 2px solid #E47100;
    position: relative;
    transform: rotate(315deg);

    transition: all .3s ease;
   margin: 0 10px;
}



.enArrow-button2:hover > .arrow2 {
    transform: rotate(315deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.enArrow-button2:hover > .arrow2::before {
    opacity: 1;
    width: 8px;
}

.enArrow-button2:hover {
    background-color: #F4D9BF;
    border-radius: 0 0 16px 16px;
}

.arrow-button2:hover {
    background-color: #F4D9BF;
    border-radius: 0 0 16px 16px;
}





.menu:hover .enArrow-button2 .arrow2 {
    transform: rotate(315deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.menu:hover .enArrow-button2 .arrow2::before {
    opacity: 1;
    width: 8px;
}


.menu:hover  .enArrow-button2 {
    background-color: #F4D9BF;
    border-radius: 0 0 16px 16px;
}

.menu:hover .arrow-button2 {
    background-color: #F4D9BF;
    border-radius: 0 0 16px 16px;
}
#layer{
    background-color: rgba(95, 92, 92, 0.195);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 14.0814px 14.0814px 0px 0px;
}


#layer2{
    background-color: rgba(95, 92, 92, 0.195);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius:  0px 0px 14.0814px 14.0814px;
}
.menu{
    cursor: pointer;
}
.menu:hover{
    cursor: pointer;
}
.dots{
    position: absolute;
    left: 2px;
    top: 0;
    z-index: -10;
}
.crcl{
    position: absolute;
    right: 0;
    width: 28% !important;
    min-width: 300px;
    padding-right: 0 !important;
    z-index: -10;
    bottom: 25px;
}
.div5img{
    height: 377.01px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;

}
.div5-1{
    display: flex;
    align-items: center;
    justify-content: center;
}
.title-5{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size:20px;


letter-spacing: -0.3px;
color: #331900;
border-style: none solid none none;
border-color: #E47100;
border-width: 14px;
padding-right: 24px;
margin-bottom: 1rem;
}
.title-5En{

    border-style: none none none solid;  
    padding-right: 0;
    padding-left: 24px; 
}

.title2-5{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
max-width: 80%;

letter-spacing: -0.3px;
color: #828282;

}

.div5Buttons div{
    display: inline-block;
    width: 15vw;
    max-width: 180px;
}
.div5Buttons{/*
    display: flex;
    justify-content: space-between;*/

}
.div5Buttons span{
    margin: 0 0.5em;
}
.btn5{
    cursor: pointer;
    border: none;
    background-color: transparent;
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
 
    height: 5vw;
   
   max-height: 60px;
    
} 
.btn5:hover{
  
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

}

.div5-2 {
      display: flex;
  justify-content: end;
    align-items: center;

}
.div5-3 div{
position: relative;
top: 30%;
}
.h-footer{
    text-align: right;
    margin-top: 18%;
}
.footer-t{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;

letter-spacing: -0.3px;
color: #331900;
margin-bottom: 30px;
display: inline-block;
margin-right: 50px;
}
.footer-d{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
 
    letter-spacing: -0.3px;
    color: #331900;
    

}
.social{
    display: flex;
    justify-content: space-between;
}
.pointer{
    cursor: pointer;
   
}
.btnf{
    cursor: pointer;
    border: none;
    background-color: transparent;
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
 max-width: 200px;
    height: 59px;
    margin-top: 4%;
    display: block;
    min-width: 150px;
}
.flogo{
margin-bottom: 40px;
margin-top: 40px;
display: block;
width: 20vw;
min-width: 150px;
}

.brnch{
  text-align: left;
}
.cr{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 14px;

text-align: center;
letter-spacing: -0.3px;
height: 45px;
color: #331900;
background: rgba(0, 0, 0, 0.06);

margin-top: 5%;

}

.cr p{
    display: inline;
    margin-bottom: 0;
    position: relative;
}
.fcircle{
    z-index: -1;

    position: absolute;
    left: 0;
    bottom: -0.0001px;
    width: 100%;
}
.thirdDiv{
margin-top: 30px;
padding-bottom:30px
}
.community{
    margin-top: 50px !important;
}


input[type="radio"]{
    cursor: pointer;
}
.countryList label{
    margin: 0 0.5em;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    letter-spacing: -0.3px;
    color: #331900;
    
}
.countryTitle{
    color: #331900;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    font-size: 27px;
}
.div5Buttons a{

    width: 12vw;
    min-width: 110px;
}

/* iso*/
.isoHeader{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
 
    letter-spacing: -0.3px;
    
    color: #E47100;
}
.isoTitle{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
 
    letter-spacing: -0.3px;
    
    color: #633235;
    

}
.isoP{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
}
.isoDiv{
    margin: 1em 0;
}
.isoBody{
    flex: 3 1;
    padding: 2em 0;
    margin-bottom: 100px; 
}
.imgV-Align div{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.toast{
position: absolute;
z-index: 10;
right: 10px;

}
.toast-header{
justify-content: center;
position: relative;
background-color: #E47100 !important;
}
.toast-header p{

    margin-bottom: 0;
    font-family: 'Cairo';
    font-size: 18px;
    color: white;

}
.toast-header .btn-close {

    position: absolute;
    left: 0;
}
.bottomDiv{
    margin-bottom: 5em;
}
@media screen and (max-width: 815px){
   
    .how {

        font-weight: 600;
        font-size: 27px;
    }
   .how-detail {
    font-size: 20px;
    font-weight: 400;
   }
    
}

@media screen and (max-width: 575px){
   
    .menueItem{
        height: 50vw;
    }
    .arrow-button2 {

        padding: 24px 16px;
        font-size: 20px;
    }
    
}

@media screen and (max-width: 767px){
    .foundUrl{
        padding-bottom: 5vw !important;
    }
    .isoImg .div5img{

width: 50%;
    }
  .isoImg{
      display: flex;
  }
    
}


@media screen and (max-width: 836px){
   
    .comm-footer{
        width: 80vw;
    }
  
     
}
@media screen and (max-width: 767px){
   
    .div5-2{
     justify-content: center;
     margin-top: 0 !important;
    }
  .decoration{
      display: none;
  }
    
}
@media screen and (max-width: 766px){
.thirdDiv iframe {
  height: 50vw;
}
.div5-3 div{
    top: 0;
}
.div5Buttons div{
    width: 22vw;
}
.btn5{
    height: 7vw;
}
}


@media screen and (max-width: 500px){
    .foundUrl{
        padding-bottom: 2.5vw !important;
    }
    .promotion button{
        font-size: 14px;
    }
    .promotion{
        padding: 1em !important;
    }
    .bottomDiv{
        margin-bottom: 2em;
    }
    .div5Buttons div{
     
        width: 30%;
        min-width: 125px;
    }
    .btn5{
        height: 45px;
    }
    .how{
       font-size: 16px;
    }
    .thirdDiv{
        margin-top: 44px;
    }
    .how-detail{
        font-size: 14px;
     }
     .arrow-button2{
        font-size: 16px;
     }
     .crslBackground{
         height: 45vw !important;
     }
     .div5img{
         height: 250px;
     }
     .title-2{
        font-size: 16px; 
     }
     .title2-2{
        font-size: 14px;  
     }
     .certified button{
        font-size: 14px;  
     }
     .certified{
       
         height:   290px ;
     }
     .comm-image div{
         height: 120px !important;
     }
     .title-5En{
        padding-left:10px 
     }
     .title-5{
        font-size: 14px;
        margin-bottom: 1em;
        padding-right:10px
     }
     .com-title{
font-size: 16px;
     }
     .com-details{
        font-size: 14px;  
     }
     .community button{
        font-size: 14px;   
     }
     .title-5En{
        font-size: 16px;  
     }
     .title2-5{
        font-size: 14px;  
     }
     .appImg{
         height: 290px !important;
     }
  
     .title-5{
         border-width: 7px;
     }
     .navbarLogo img {
max-width: 150px !important;

     }
     .navbarOptions .orderNow{
        font-size: 14px !important;  
     }
     .navbarTop p{
        font-size: 13px !important;    
     }
     .thirdDiv{

    padding-bottom: 40px;

     }
     .isoHeader{
         font-size: 16px;
     }
     .isoTitle{
         font-size: 14px;
     }
     .isoP{
        font-size: 13px; 
     }
     .isoTitle{
        font-size: 14px; 
     }
     .vatTitle{
        font-size: 16px !important;   
     }
     .cancelOrder{
        font-size: 14px !important; 
        line-height: normal !important;
     }
     .cancel{
        font-size: 14px !important; 
     }
     .confirm{
        font-size: 14px !important; 
     }
}