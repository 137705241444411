.carTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 45px;
/* identical to box height */


letter-spacing: -0.3px;

color: #4F4F4F;
}

.addressInput textarea{
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    border: none;
    padding: 0.6em 0.5em;
}
@media screen and (max-width: 500px){
    .carTitle{
font-size: 16px;
    }
    .addressInput label{
        font-size: 14px;
    }
    .addressInput input{
        font-size: 13px;
    }
    .addressInput button{
        font-size: 13px;
    }
    .addressInput span{
        font-size: 13px !important;   
    }
}