 .chooseType{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
margin-bottom: 0;
    letter-spacing: -0.3px;
    
    color: #828282;
 }
 .titleDiv .how{

margin-bottom: 2em;

 }
 .titleDiv{

    background: rgba(228, 113, 0, 0.06);
border-radius: 20px;
margin-top: 2em;
padding: 2em;
height: 27em;
 }
 .optionsDiv{
  position: relative;
  bottom: 17em;

 }



 .optionsDiv .menueItem{
background-color: white;
background-size: contain;

 }
 .optionsDiv .menu{

    padding: 1em;
    background-color: white;
  
 }
 .optionsDiv button{

    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 25px;

letter-spacing: -0.3px;

color: #FFFFFF;
background: #E47100;
border-radius: 8px;
border: none;
width: 80%;
margin-top: 1em;
padding: 0.5em 0;
 }
 .ordrTypes{
   position:relative;
   bottom:19em;
 }

 .optionsDiv button:hover{
    background: #ed8319;
 }
 @media screen and (max-width: 431px){
   
  .titleDiv  .how {

       margin-bottom: 1em;
    }
  .optionsDiv {

bottom: 19em;
  }

}

 
 .ordrNw{
   height:90vh
 }
@media screen and (max-width: 500px){
   .ordrTypes{
      bottom:19em;
     }
       
  .chooseType {
 
   font-size: 16px;
     }
     .titleDiv{
        margin-top: 2em;
     }
     .optionsDiv button{
      font-size: 14px;
     }
   
 }

 @media screen and (max-width: 575px){

   .ordrNw{
      height: 270vw !important;
      min-height: 1200px ;
   }

 }
 @media screen and (max-width: 766px){
 .cpPayment-div2 {
  margin-bottom: 2.5em;
}
}

@media screen and (min-width: 1700px){/*

.ordrNw{
  max-height: 73vh;  
  margin-bottom: 8vw;
}
*/
}