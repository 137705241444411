


.footer{
  
    background-color: #420F12;
  color: wheat;

  padding:20px;
padding-bottom: 0;

position:relative;
bottom: 0;
}

.align{
    display: flex;
    justify-content: space-between;
    align-items: center;
   

}  
.fluid{
    --bs-gutter-x: 3rem;

}

.vatTitle{

    color: #e47100;
    font-family: 'Cairo';
    font-weight: 700;
    font-size: 22px;
  
  
   
margin: 1.2em 0;
 }

.vat {
    background: #fff;
    max-width: 800px;
    padding: 30px;
    margin: 30px auto;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
}
.vat img{
   
    vertical-align: middle;
    border-style: none;
    width: 100%;
}

.footerPayment img {
   
margin: 0 1em;
}

    .footerVat{
     
    }
    .footerVat img{
       cursor: pointer; 
     }
    .fLogo{
       text-align: start; 
    }


.footer .footerlogo{
    max-width: 250px;
    width: 80%;
    margin-bottom: 1rem;
}

.chilli{
  
position: relative;
width: 100%;

}

.ftr2{ 
    color:white;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
text-align: right;
letter-spacing: -0.3px;
}

.sm{
    display: flex;
    justify-content: space-between;
}

.mid{
    display: flex;
    justify-content: center;
}
.footercr{
display: flex;
justify-content: center;
align-items: center;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 26px;
text-align: center;
letter-spacing: -0.3px;
height: 45px;
color: #fff;
background-color: #420F12; 
}
.footercr p{margin-bottom: 0;}

.tandc{
    text-align: start;
    margin-top: 40px;
    margin-bottom: 100px;
}
.tandc1{font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;
    text-align: start;
    letter-spacing: -0.3px;
    margin-bottom: 1rem;
    color: #000000;}

.tandc2{font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 45px;
    /* identical to box height */
    
    text-align: start;
    letter-spacing: -0.3px;
    
    color: #000000;
    }
.tandc3{font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;
   text-align: start;
    letter-spacing: -0.3px;
    
    color: #232323;
    }
    .footerAlign{
       text-align: start;
    }
    .footerLink{
     color:#E47100 !important
    }
    @media screen and (max-width: 990px){
   
        .decor{
            display: none;
        }
            
             
         }
