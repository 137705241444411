 .client-care{
text-align: center;
margin: 2em 0;
 }
 .cc-header{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
  margin-top: 2em;
    letter-spacing: -0.3px;
    line-height: 30px;
    color: #633235;
    
     }
     .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{

      bottom: -4px !important
     }
     .cc-phone{


        font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 22px;
letter-spacing: -0.3px;
margin-bottom: 3em;
color: #633235;

     }
     .cc-phone span{

        margin: 0 2em;
        color: #E47100;
     }
     .cc-image{

        height: 300px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
     }
     .cc-body{
         margin: 5em 0;
     }
     .cc-form{
       padding: 1.5em !important;
       
         background: #F4F4F4;
border-radius: 12px;

     }

     .cc-form label{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;

        letter-spacing: -0.3px;
        
        color: #4F4F4F;
        display: block;
        margin-bottom: 0.5em;
     }
     .cc-form input{

width: 100%;
background: #FFFFFF;
border-radius: 8px;
border: none;
margin-bottom: 1em;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
padding: 0.2em 0.5em;
     }
     .cc-form input::placeholder{

        color: #ACACAC; 
     }
     .cc-form button{



background: #E47100;
border-radius: 8px;
border: none;

font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;

letter-spacing: -0.3px;

color: #FFFFFF;
padding: 0.2em 0;
width: 30%;
     }
     .cc-form button:hover{



        background: #f4881d;}
        .cc-alignBTn{
            display: flex;
            justify-content: end;
        }
.complaintData{
    height: 9em;

width: 100%;
background: #FFFFFF;
border-radius: 8px;
border: none;
margin-bottom: 1em;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
padding: 0.2em 0.5em;
}
.complaintData:focus{
    outline: none;
    border:2px solid #E47100;
}
.complaintImg{
    position: absolute;
    left: 0;
    bottom: 0;
    background: transparent !important;
    text-indent: -999em;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    letter-spacing: -0.3px;
    text-decoration-line: underline;
    
    color: transparent;
cursor: default;
    
}
.file label{
display: inline-block;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
margin: 0 1em;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    
    color: #E47100;
   cursor: pointer;
}
.file div{
    display: flex;
   align-items: center;
   margin-bottom: 2em;
   justify-content: end;
}


.file {
    position: absolute;
    bottom: 0;
    right: 0;
}
.file button{
    
    background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
border-radius: 100%;
border: none;
color: #E47100;
width: 40px;
height: 40px;
display: flex;
align-items: center;
text-align: center;
justify-content: center;
cursor: default !important;
}
.file button:hover{
background: #E47100;
color: white;

}

.complaintImg:focus{



    border-color:transparent !important ;
}
        .complaint{
            background: #FDF4EC;
box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
border-radius: 8px;
text-align: center;

width: 130px; 
height: 130px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-evenly;
        }
.complaintsCrsl {
    height: 10em !important;
  
    
}
.complaintsCrsl .swiper-pagination-bullet {
    width: 47.34px;
    height: 6px !important;
    border-radius: 20px;
}
 

        .complaint p{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            letter-spacing: -0.3px;
            
            color: #4F4F4F;

        }
        .complaint img{
            width: 40px;
height: 40px;
        }





.CrslAr .swiper-button-next {
    background-image: url("../Images/clientCare/arrow-left.svg") !important;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
  
  
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
   
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .CrslAr  .swiper-button-next:hover {
  
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
   
  }
  .CrslAr   .swiper-button-next:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
  }
  
  
  .CrslAr .swiper-button-prev {
    background-image: url("../Images/clientCare/arrow-right.svg") !important;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
  
  
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
   
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .CrslAr  .swiper-button-prev:hover {
  
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
   
  }
  .CrslAr   .swiper-button-prev:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
  }
  
  
  .CrslEn .swiper-button-prev {
    background-image: url("../Images/clientCare/arrow-left.svg") !important;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
  
  
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
   
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .CrslEn  .swiper-button-prev:hover {
  
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
   
  }
  .CrslEn   .swiper-button-prev:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
  }
  
  
  
  .CrslEn .swiper-button-next {
    background-image: url("../Images/clientCare/arrow-right.svg") !important;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
  
  
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
   
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .CrslEn  .swiper-button-next:hover {
  
    box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
   
  }
  .CrslEn   .swiper-button-next:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
  }

  @media screen and (max-width: 766px) {
    .careForm {
 padding: 0 5vw;
    }
    

  }
  @media screen and (max-width: 500px) {
    .cc-header {
font-size: 14px
    }
    .cc-phone{
      font-size: 13px
    }
    .complaint p{
      font-size: 13px
    }
    .cc-form label{
      font-size: 14px
    }
    .cc-form button{
      font-size: 13px
    }
    .cc-form input{
      font-size: 13px
    }
    .cc-image{
      height: 170px;
    }
    .complaintsCrsl .swiper-pagination-bullet{
      width: 25px !important;
      height: 4px !important
    }
    .complaint{
      width: 100px;
    }
    
  }


