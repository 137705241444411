.userProfile{
    display: flex;
  
 height: 100%;
}
.upBody{
flex:3;
padding: 2em;
margin-bottom: 100px;
}
.upTable{
    background: #FFFFFF;
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1em 0;
    }
    .upTable-title{
        font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 25px;
line-height: 45px;
letter-spacing: -0.3px;
color: #331900;
    }
    .table{
        --bs-table-striped-bg: #FBFBFB !important;
    }
    .table tr { 
        line-height: 50px !important;
     }


     .table th { 
        font-family: 'Cairo' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 30px !important;
  text-align: center !important;
        letter-spacing: -0.3px !important;
    color: #633235 !important;
    
    }
  
    .table td { 
        font-family: 'Cairo' !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 30px !important;
  text-align: center !important;
        letter-spacing: -0.3px !important;
    color: #828282 !important;

    }
   
    .table button { 
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: -0.3px;  color: #E47100;
        background: #FEF1E4;
border-radius: 24px;
border: none;
width: 100%;
padding: 0.5em 0;
white-space: nowrap;
    }
.taxData  p{
margin-bottom: 15px;
height: auto !important;
display: flex !important;
justify-content: space-between;
align-items: center;
color:  #344054;
font-family: Cairo;

font-style: normal;
font-weight: 600;

}
.taxCardActice{
    border-color: #E47100 !important;
}
.taxCardActice .taxTitle p{
color: #E47100 !important;

}
.customerType label{
    margin: 0 0.5em !important;
}
.customerType .col-6{
display: flex;
align-items: center;

}

.customerType input{
    height: 20px;
    width: 20px !important;
}
.customerType{
    margin-top: 1em;
}
.taxTitle{
    display: flex;
    margin-bottom: 1em;
    align-items: center;
}
.taxTitle input{
    height: 20px;
    width: 20px;
}
.taxTitle p{
    margin-bottom: 0;
    display: inline-block;
    color: #263238;
font-size: 18px;
font-weight: 700;
line-height: normal;
}
.taxData  p span{
    color:  #98A2B3 !important;

font-size: 16.5px !important;

font-weight: 500 !important;
}
.taxCard{
    padding: 18px;
    border-radius: 8px;
border: 1.023px solid #BDBDBD;
display: flex;
flex-direction: column;
justify-content: space-between;
}
.taxCard button{
    font-size: 16px;
    font-family: cairo;
    color: white;
    border: none;
    background-color: #E47100;
    border-radius: 6px;
    padding: 8px;
    margin-top: 28px;

}
.taxCard button:hover{
    background-color: #ee841a;

}
    .table button:hover { 
        background: #fbd1a6;
    }
    .invoiceTable th{
        text-align: start !important;
      }
      .invoiceTable td{
        text-align: start !important;
      }
.complaints th{
    white-space: nowrap;
}

.upSideNave{
    flex:1;
    background:  #420F12;;
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
  
 
   max-width: 300px;
 
  
margin-top: 3em;
    margin-bottom: 4em;
    border-radius: 0px 10px 10px 0;
   
    text-align: center;
    padding-top: 2em;

    display: flex;
    flex-direction: column;
    position: -webkit-sticky; /* for Safari */
  /*   position: sticky;*/
    top: 60px;
 
    align-self: flex-start; 
    width: 25vw;
}

.upImageBorder{
    border: 4px solid #E47100;
    border-radius: 50%;
    padding: 0.3em;
}
.upTop{
    display: flex;
    justify-content: center;
}

.upImageBorder .profileImage{
    background-size: cover;
    background-repeat: no-repeat;
    background-position:  center;

    border-radius: 100px;
    width: 15vw;
    height: 15vw;
    max-width: 200px;
    max-height: 200px;
}


.upName{
    
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 35px;
letter-spacing: -0.3px;

color: #FFFFFF;
}

.upPhone{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: 0.7px;

color: rgba(255, 255, 255, 0.5);
border-bottom: 0.5px solid rgba(255, 255, 255, 0.15);
padding-bottom: 2em;
}

.upLinks{
 
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;

text-align: initial;
letter-spacing: -0.3px;

color: rgba(255, 255, 255, 0.5);

}
.upLinks p:hover{
   
background: rgba(251, 251, 251, 0.1);
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-right: 3px solid #E47100;
color: #E47100;

}
.upLinksEn p:hover{
    border-right:none;
    border-left: 3px solid #E47100;



}

.upLinksEn p{
 
    border-left: 3px solid transparent;



}

.upLinksActive {
   
    background: rgba(251, 251, 251, 0.1);
    box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
    border-right: 3px solid #E47100 !important;
    color: #E47100;
    }

    .upLinksEn .upLinksActive{
        border-right: none !important;
        border-left: 3px solid #E47100 !important;
    
    }

.userData{

    text-align: center;
    padding: 3em 0;
    background: #F4F4F4;
    border-radius: 12px;

}
.userData label{

margin-top: 1em;
    display: block;

font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;

margin-bottom: 0.5em;
letter-spacing: -0.3px;

color: #4F4F4F;

   }
   .map{
height: 45vh !important;
position: relative;
top: 50%;
transform: translateY(-50%);
   }

.mapAlign{
    display: flex;
    justify-content: center;
    align-items: center;
}

   .userAddAddress{

    background: #F4F4F4;
border-radius: 12px;
padding: 1em;
   }

   .userAddAddress input{
    background: #FFFFFF;
    border-radius: 8px;

   }
   .selectCity{
    background: #FFFFFF;
    border-radius: 8px;
    display: block;
    width: 100%;
    padding: 8px 5px;
    font-family: 'Cairo';
   }
   #pass3{
    padding: 0.6em 1em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 3px;
    color: #ACACAC;
   }

 .userData  .inputDiv{

width: 55%;

 }
   .userData input{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #633235 ;
    border-radius: 8px;
    letter-spacing: -0.3px;
    border: none;
   
 width: 100%;
    padding: 0.5em;
}

.changePass{
    background-color: transparent;
    border: none !important;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 31px;
margin-top: 0.5em;
letter-spacing: -0.3px;
text-decoration-line: underline;

color: #4F4F4F;


}

.dataEdit{
    background: #E47100;
border-radius: 8px;
border: none;

font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
width: 20%;
}

.formBtns{

    margin-top: 2em;
}
.formBtns span{


display: inline !important;
margin: 0 1em !important;
}
.cancelEdit{
width: 20%;

    border: 1px solid #E47100;
    border-radius: 8px;
background-color: transparent;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #E47100;

}

.editImage{
   
    background: transparent;
    border: none;
    color:  #ACACAC;
    position: absolute;
    left: -35px;
}

.userData .upImageBorder{
    padding: unset;
    border-color: transparent;
}
.profileBody{
    margin-top: 2.5em;
}
.userData .profileImage{
   width: 13vw;
  height: 13vw;
  min-height: 90px;
  min-width: 90px;
}

.userData span{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgb(193, 22, 22);
    display: block;
margin: 0.2em 0;
   }
   .userData select{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    background: #FFFFFF;
    border-radius: 8px;
    letter-spacing: -0.3px;
    border: none;
    color: #ACACAC;
 width: 100%;
    padding: 0.5em;

   }

.upLinks p{
    border-right: 3px solid transparent;
padding: 0.5em 1em;
cursor: pointer;}
/* complaints*/
.table2  th {
    text-align: right !important;
    padding-right: 2em !important;
}
.table2  td {
    text-align: right !important;
    padding-right: 2em !important;
}
.table2  td {
    text-align: right !important;
    padding-right: 2em !important;
    
}
.table2 tbody tr{ 
    height: 100px !important;
 }
 /* transactions*/

 .table2 button{ 
    padding: 0.7em 0 !important;
 }

 tr:nth-child(even) .transaction{
   color: #27AE60 !important;
  
    }
    tr:nth-child(odd) .transaction{
        color:  #DB095B !important;
       
         }
         .transactionCredit{
            position: relative;
            background: #FFFFFF;
            box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
          
            padding: 1em;
            padding-bottom: 0;
            text-align: right;
         
            margin-bottom: 2em;
         }
         .tc-p1{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
          
            color: #643335;
            margin-bottom: 0;
         }
         .tc-p22{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 700;
            font-size: 64px;
          
            letter-spacing: -0.3px;
            color: #E47100;
            display: inline-block;
            margin-bottom: 0 !important;
            margin-top: 0 !important;
         }
         .tc-p3{font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 30px;
   letter-spacing: -0.3px;
  color: #633235;
display: inline-block;
margin: 0.5em;
margin-bottom: 0;}

.tc-img{

}
.tc-img img{
   position: absolute;
   bottom: 0px;
   left: 0;
}
.tc-bg{
  
    bottom:-5px !important;
}



@media screen and (max-width: 993px) { 
    
    .tc-img img{
     
        top: 47px ;
       
     }
}

/* Address*/

.upTable-button{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;

letter-spacing: -0.3px;
color: #E47100;
background: transparent;
border: 1px solid #E47100;
border-radius: 10px;
padding: 0.3em 1em;
display: flex;

align-items: center;
}


.upTable-button:hover{
color: white;
background-color: #E47100;
}

.upAddress{
 
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
}

.upAdressCard{
    background: #FFFFFF;
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-radius: 12px;
position: relative;
}
.upAdressCard .upAdressCard-div{
    display: flex;
    justify-content: space-between;
}

.upAdressCard p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  
    letter-spacing: -0.3px;
    color: #828282;
    margin-bottom: 0.3em;

    height: 3.3em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 1em;

}
.upAdressCard-p1{
    font-weight: 700 !important;
    color: #331900 !important;
    height: auto !important; 
}
.upAdressCard-p2{
    font-weight: 400 !important;

color: #331900 !important;
}

.upAdressCard-btns{
position: absolute;
bottom: 0;
}
.upAdressCard-btns p{
    display: inline-block;
}
.upAdressCard-btns button{

   background: transparent;
border: none;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;
color: #E47100;
}

.upAdressCard-btns button:hover{

    color: #ff8307;
}
.upAdressCard-btns span{
    margin:0 10px;
}
.upAdressCard-btns p{
    border-left: 1px solid #BEBEBE;
    display: inline-block;
}
.upAdressCard-btn1{
    color: #DB095B !important;
}
.upAdressCard-btn1:hover{
    color: #ff136d !important;
}

.mobile-upSideNav{
    padding: 2em;

}
.mobile-upSideNav-top{
    display: flex;
    align-items: center;
  
}
.mobile-upSideNav img{
  
   width: 130px;
   height: 130px;
  }
  .upInfo{

    margin: 0 1em;
  }
.upInfo-name{
    font-family: 'Cairo';
font-style: normal;
font-weight: 900;
font-size: 20px;
line-height: 60px;
letter-spacing: -0.3px;
color: #331900;
margin-bottom: 0;
} 
.upInfo-number{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: 0.7px;
color: #BEBEBE;
}

  .mobile-upSideNave div{
  
   display: inline-block;
   }

   .mobile-upLinks span{
    border-left: 1px solid #BEBEBE;
    margin:0 1em;
    padding: 0.5em 0;
   }

.mobile-upLinks p{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 41px;
letter-spacing: -0.3px;
color: #828282;
display: inline-block;

cursor: pointer;
}
.mobile-upLinks p:hover{
    color: #E47100;
    border-bottom:  2.5px solid #E47100;
}

.mobile-upLinksActive{
    color: #E47100 !important;
    border-bottom:  2.5px solid #E47100;
}

.logoutModal{
    padding: 0 !important;
}
.barCode svg{
    width: 80vw;
    max-width: 400px;
 
}

.barCode {
    width: 80vw;
    max-width: 400px;
    max-height: 300px;
}
.transactionCreditEn{
    text-align: left;
}
.transactionCreditEn .tc-img img {

    right: 0;
    left: auto;
    -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.exCurrencyEn{
    margin-right: 0;
    margin-left: 0.5em;
}
.profileLoader{
    flex: 4;
}
@media screen and (max-width: 500px){
    .barCode svg{
     width: 93vw;
    }
    .barCode {
        max-height: 260px;
        width: 100%;
    }
    .decore{
bottom: -15px;
    }
}
@media screen and (max-width: 832px){
    .upSideNave{
        display: none;
       
    }
    .userProfile {
    
        flex-direction: column;
    }
    
    .profileLoader{
        flex: none;
        height: 56vh !important;
    }
   
}

@media screen and (min-width: 833px){
   
  .mobile-upSideNav{
    display: none;
  }
    
    
   
}

@media screen and (max-width: 500px){
    .upInfo-name{
     font-weight: 600;
       font-size: 30px;
    }
   
    .mobile-upLinks p{
font-size: 20px;
    }
     
    .table button{
font-weight: 600;
    }
    .upTable-button{
font-size: 20px;
padding: 0 1em;
    }
    .upTable-title {
        font-weight: 600;
    }
    .userAddAddress{
padding: 1em;
    }

 .userData  .inputDiv{

    width: 85%;
    
     }
   
}
.chrgWallet{
    text-align: center;
}
.chrgWallet button{
  font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;

letter-spacing: -0.3px;
color: #E47100;
background: transparent;
border: 1px solid #E47100;
border-radius: 10px;
padding: 0.3em 1em;
display: inline-block;

align-items: center;
width: 30%;
min-width: 200px;
}


.chrgWallet button:hover{
color: white;
background-color: #E47100;
}
.chargeWallet .errMsg{
font-weight: 400;
font-size: 18px;
width: 60%;
min-width: 300px;
text-align: start;
margin: auto;
}
.chargeWallet input[type="number"]{
display: inline-block;
    background: #FFFFFF;
    border-radius: 8px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;

letter-spacing: -0.3px;
border: none;
color: #ACACAC;
padding: 0 1em;
width: 60%;
min-width: 300px;
margin-top: 1em ;
}

.chargeWallet{
   background-color: #F4F4F4;
   margin-bottom: 5em;
margin-top: 3em;}
.chargeWallet label{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;

letter-spacing: -0.3px;
margin: 0 0.5em;
color: #4F4F4F;

}
.chargeWallet span{
    margin: 0 0.5em;
}
.chargeBtn{
width: 60%;
margin: auto;
    text-align: center;
    min-width: 300px;
    margin-top: 1em;
}
.chargeBtn button{
    font-family: 'Cairo';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  
  letter-spacing: -0.3px;
  color: #E47100;
  background: transparent;
  border: 1px solid #E47100;
  border-radius: 10px;
  padding:0 1em;
  display: inline-block;
  
  align-items: center;
  width: 40%;
  min-width: 150px;
  }
  
  
  .chargeBtn button:hover{
  color: white;
  background-color: #E47100;
  }
.chrgCenter{
min-width: 300px;
width:60%;
margin:auto;
text-align:initial;

}

@media screen and (max-width: 500px) {
    .upInfo-name{
        font-size: 16px;
        margin-bottom: 0;
        line-height: normal;
    }
    .upInfo-number{
        font-size: 14px;
    }
    .upInfo{
position: relative;
top: 10px;
    }
.upImageBorder{
border-width: 2px;
padding: 0.1em
}
.mobile-upLinks p{
    font-size: 14px;
}
.mobile-upLinks span{
margin: 0 0.5em;
}
.upTable-title{
    font-size: 16px; 
}
.table th{
    font-size: 14px !important; 
}
.table td{
    font-size: 14px !important; 
}
.table button{
    font-size: 14px !important;    
}
.upTable-button{
    font-size: 14px !important; 
    height: fit-content;
    padding: 0.5em 1em;   
}
.profileBody{
    margin-top: 0;
}
.tc-p22{
    font-size: 20px !important;  
}
.tc-p3{
    font-size: 12px;
}
.tc-p1{
    font-size: 14px;
}
.tc-img img{
width: 50px;
}
.transactionCredit{
height: 90px;
}
.chrgWallet button{
    font-size: 14px;   
}
.chargeWallet label{
    font-size: 14px; 
}
.chargeBtn button{
    font-size: 14px;
    line-height: 40px;
}
.dateT{
    font-size: 13px;   
}
.date{
    font-size: 14px !important;   
}
.spaceAround{
height: 160px;
}
.exCredit{
    font-size: 16px !important;
}
.exCurrency{
    font-size: 16px !important;
}
.currencyValue{
    font-size: 16px !important;
}
.exBox{
padding: 0;
}
.pExch button{
    font-size: 14px;
    height: 46px;
}
.rotate{
    height:40px;
    position: relative;
    top: 5px;
}
.userData label{
font-size: 14px;
}
.inputDiv span{
    font-size: 12px !important;   
}
.eyeEn{
top: 5px !important;
}
.eye{
    top: 5px !important; 
}
.changePass{
    font-size: 14px;
}
.dataEdit{
    font-size: 14px;
    width: 30%;
}
.cancelEdit{
    font-size: 14px;
    width: 30%;
}
.errMsg{
    font-size: 12px; 
}
.currencyValue{
    font-size: 14px; 
}
text{
    font-size: 16px !important;     
}
.spacebtn p{
    font-size: 14px;  
}
.upAddress{
    align-items: center;
}
.chrgCenter img{
    width: 40px;
}

.upAdressCard p{
    font-size: 13px ;    
}
.upAdressCard-p1{
    font-size: 14px !important;    
}
.upAdressCard-btn1{
    font-size: 13px !important; 
}
}
.transaction-history {
    border-collapse: separate; 
    width: 100%;
  }
  
  .transaction-history th,
  .transaction-history td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    text-align: center;
    font-family: 'Cairo';
  }
  
  .transaction-history th {
    background-color: #f2f2f2;
    border: none;
    font-family: 'Cairo';

  }
  
  .transaction-history-container {
    max-height: 450px; 
    overflow-y: auto; 
    width: 100%; 
  }
  
  .transaction-history tr:last-child td {
    border-bottom: none; 
  }
  
  .transaction-history tr th {
    border-bottom: 2px solid #ddd;
    font-family: 'Cairo';
  }
  .elevated-card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    margin-top: 70px; 
    width: 38vw;
    margin-right: 10px;
    margin-left: 10px;
    height: 20vw;
    border-radius: 15px;
    width: calc(100% - 20px); 
    height: auto;
    max-width: 500px; 
      

  }
  
  .center-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .center-content h5, .center-content p {
    font-family: 'Cairo';
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}
.radio-button {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #ddd;
    margin-right: 10px;
    vertical-align: middle;
    position: relative;
}
.radio-button::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: #e67e22;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.2s ease;
}
.card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 20px;
    position: relative;
}

.card input[type="radio"] {
    display: none;
}

.card label {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
}

.card input[type="radio"]:checked + label .radio-button::after {
    transform: scale(1);
}
.subscribe-btn {
    background-color: #e67e22;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    width: 300px;
}

.card-title {
    color: #333333;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.card-body p {
    margin: 10px 0;
}

.price {
    display: block;
    font-weight: bold;
    color: #e67e22;
    margin-top: 10px;
}


.payment-toggle {
    width: 100%;
    max-width: 500px; /* or your preferred max width */
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .payment-options {
    display: flex;
    flex-direction: column;
  }
  
  .payment-option {
    display: flex;
    align-items: center;
    border: 2px solid #e0e0e0; /* Light grey border */
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0;
    transition: border-color 0.3s;
  }
  
  .payment-option:hover {
    border-color: #c0c0c0; /* Darker grey on hover */
  }
  
  .payment-option input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  .payment-option input[type="radio"]:checked + svg {
    color: #000; /* Change the icon color when selected */
  }
  
  .payment-option.selected {
    border-color: #000; /* Black border for selected option */
  }
  
  .payment-option svg {
    margin-right: 15px;
    color: #c0c0c0; /* Light grey for icons by default */
    transition: color 0.3s;
  }
  
  .payment-option span {
    font-size: 16px;
    color: #333;
  }
  
  .payment-option input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .payment-option label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  
  .payment-option label:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 50%;
    background: #f0f0f0;
    border: 2px solid #d0d0d0;
    margin-right: 10px;
  }
  
  .payment-option input[type="radio"]:checked + label:before {
    background: #000;
    border-color: #000;
  }

  .card-subscription {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    width: 400px; 
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin: 20px auto; 
    font-family: Arial, sans-serif;
    background-color: #fff;
    max-height: 30vh;
    max-width: 400px; 
    overflow: auto;
}

.card-subscription-content {
    border: none; 
    padding: 10px;
    margin-bottom: 20px;
}

.row-sub {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px; 
    align-items: center; 
    font-family: 'Cairo';

}

.row-sub label {
    font-weight: bold;
    color: #333; 
    font-family: 'Cairo';

}

.row-sub .value {
    color: #555;
    word-break: break-word;
    font-family: 'Cairo';

}

.roundedDialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px); /* Apply the blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11; /* Ensure this is below the modal content */
}

.btn-transfer {
    display: flex;
    align-items: center;
    margin-top: 8vh;
    justify-content: center;
    flex-direction: row;
}

.confirm-transfer {
    width: 12vw;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    background-color: #E47100;
    border-radius: 10px;
    margin: 2%;
}

.confirm-transfer p {
    margin-top: 2vh;
    font-family: 'cairo';
    font-weight: bold;
    color: white;
}

.cancel-transfer {
    width: 12vw;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1%;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.cancel-transfer p {
    
    margin-top: 2vh;
    font-family: 'cairo';
    font-weight: bold;
    color: #E47100;
}


.modal-content1 {
    position: fixed !important ;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 12px 1em;
    border-radius: 20px;
    
  
    z-index: 12;

   display: unset !important;
width: 600px ;
height: 75vh;
}

/* Responsive grid */
@media (max-width: 1200px) {
    .card-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 900px) {
    .card-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .card-container {
        grid-template-columns: 1fr;
    }
}
 
  
@media screen and (min-width: 1700px){/*
    .upTable-title{
        font-size: 1.8vw;
        margin-bottom: 1.5em;
    }
.userData label{
    font-size: 1.5vw !important;

}
.cartProcess{
overflow: hidden;
}
.upSideNave{
    max-width:19vw;
}
.userData input{
    font-size: 1.3vw !important;
 
}
.changePass{
    font-size: 1.6vw !important;
 
}
.formBtns button{
    font-size: 1.4vw;
    padding: 0.3em 0;
}
    .table th{
        font-size: 1.3vw !important;
 
    }
    .cancelOrder{
        font-size: 1.8vw !important;
        line-height: 2.5vw !important;
    
    }
    .modalBtns button{
        font-size: 1.4vw !important;

    }
    .modal-content{
    max-width:30vw !important

    }
    .close-modal svg{
width: 2.5vw;
height: 2.5vw;
    }
    .table td{
        font-size: 1.3vw !important;
   
    }
    .table button{
        font-size: 1.3vw !important;
  
    }
    .upName{
        font-size: 1.8vw;
        margin-top: 0.5em;

    }
    .upPhone{
        font-size: 1.6vw;
 
    }
    .upImageBorder .profileImage{
        max-width: unset;
        max-height: unset;
        border-radius: 50%;
    }
    .upImageBorder{
border-width: 0.35vw;
    }
    .editImage svg{

        width: 2.5vw;
        height: 2.5vw;
    }
    .upLinks p{
        font-size: 1.4vw;
 border-width: 8px;
    }
   .upLinksActive{
    border-width: 8px !important;  
   }
   .upAdressCard p{
       font-size: 1.4vw;
   }
   .upAdressCard-btns button{
    font-size: 1.4vw;
  
   }
   .upAdressCard-btns svg{
    width: 1.8vw;
    height: 1.8vw;
  
   }
   .upAdressCard-btns{
       bottom: 0.5em;
   }
   .upTable-button{
    font-size: 1.7vw;

   }
   .upTable-button svg{
    width: 2.2vw;
    height: 2.2vw;
  
   }
   .addressTitle{
    font-size: 1.7vw;

   }
   .addressInput label{
    font-size: 1.4vw;

   }
   .userAddAddress input{
    font-size: 1.3vw !important;

   }
   .userAddAddress input::placeholder{
    font-size: 1.3vw !important;

   }
   .addressInput button{
    font-size: 1.4vw ;
    padding: 0.5em 0;

   }
   .tc-p1{
    font-size: 1.4vw ;
 
   }
   .tc-p3{
    font-size: 1.3vw ;
  
   }
   .tc-p22{
    font-size: 5.5vw ;
  
   }
   .tc-img img{
      
       height: 8vw;
   }
   .chrgWallet button{
    font-size: 1.6vw ;

   }
   .cpMiddle-title2{
    font-size: 1.8vw ;

   }
   .chargeWallet input[type="number"]{
       font-size: 1.3vw !important;
       padding: 0.5em 1em;
   }
   .chrgCenter{
       display: flex;
       align-items: center;
       margin-bottom: 0.5em;
   }
   .chrgCenter img{
       width: 4vw;
   }
   .chrgCenter input{
height: 1.6vw !important;
    width: 1.6vw !important;
   }
   .chargeWallet input[type="number"]::placeholder{
    font-size: 1.3vw;
}
.chargeWallet label{
    font-size: 1.4vw ;

}
.chargeBtn button{
    font-size: 1.7vw ;
padding: 0.3em 0;
}
.exCredit{
    font-size: 1.7vw ;
  
}
.exBox .exCurrency{
    font-size: 1.6vw ;

}
.pExch button{
    font-size: 1.4vw ;
padding: 1em 0;
height: unset;
}
.exMid .rotate{
    width: 6vw;
}
.dateT{
    font-size: 1.3vw ;

}
.cardTop .date{
    font-size: 1.4vw ;

}
.points{
    font-size: 1.4vw ;
   
}
.cardTop img{
    width: 3.4vw !important;
    height: 3.4vw !important;

}
.pointsT{
    font-size: 1.4vw ;

}
.barCode{
    max-width: 30vw;
}
.barCode svg{
    max-width: 30vw;
background-color: white; 
margin-bottom: 4vw; 
box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
height: 6vw;
}
.barCode text{
    font-size: 1.4vw !important;
}
.barCode .cardTop{
    align-items: center;
}
.spaceAround .decore{
    width: 15vw;
}
.errMsg{
    font-size: 1.5vw !important;
}*/
}
