.crslAr .carousel-control-next {

    left: 0 !important;
    right: auto !important;
    transform: rotate(180deg) !important;
    }
    .crslAr .carousel-control-prev {
    right: 0 !important;
    transform: rotate(180deg) !important;
    }
    .myCarousel .swiper{
        height: auto !important;
    
    }
    .crslBackground{
        height: 30vw;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        
        border-radius: 20px;
      }

.carousel .slide img {

    height: 40vw !important;
    min-height: 250px;
    display: none;
}
.carousel{
    text-align: center;
}
.carousel .control-dots .dot {

background: #e47100 !important;
box-shadow: none !important;
}
.crslBackground2{
    height: 40vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center ;
  
   max-height: 30em;
   
  }

 .btnsAr .swiper-button-prev {
    background-image: url("../Images/arrow-right.svg") !important;
    background-repeat: no-repeat;
    background-color: rgba(106, 38, 38, 0.476);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .btnsAr  .swiper-button-prev:hover {
 
    background-color: rgba(106, 38, 38, 1);
   
  }
  .btnsAr   .swiper-button-prev:after, .myCarousel  .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
  



.btnsAr .swiper-button-next {
    background-image: url("../Images/arrow-left.svg") !important;
 
    background-repeat: no-repeat;
    background-color: rgba(106, 38, 38, 0.476);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
    
  }
  .btnsAr .swiper-button-next:hover {
 
    background-color: rgba(106, 38, 38, 1);
   
  }
  .btnsAr .swiper-button-next:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
.btnsEn .swiper-button-prev {
    background-image: url("../Images/arrow-left.svg") !important;
    background-repeat: no-repeat;
    background-color: rgba(106, 38, 38, 0.476);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
    
  }
  .btnsEn  .swiper-button-prev:hover {
 
    background-color: rgba(106, 38, 38, 1);
   
  }
  .btnsEn   .swiper-button-prev:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
  
.btnsEn .swiper-button-next {
    background-image: url("../Images/arrow-right.svg") !important;
 
    background-repeat: no-repeat;
    background-color: rgba(106, 38, 38, 0.476);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
    
  }
  .btnsEn .swiper-button-next:hover {
 
    background-color: rgba(106, 38, 38, 1);
   
  }
  .btnsEn .swiper-button-next:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}




@media screen and (min-width: 1525px){

  .swiper{
    max-height: 35vw !important;
  }
  .swiper-pagination{

    height: 60px !important;
  }
  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{

    height: 17px;
    width: 17px;
    margin-top: 1em !important;
  }

  .btnAr .swiper-button-prev {
    padding: 2em 2.3em !important;
    background-size: contain !important;
  }
  .btnAr .swiper-button-next{
    padding: 2em 2.3em !important;
    background-size: contain !important;
  }
  .btnEn .swiper-button-prev {
    padding: 2em 2.3em !important;
    background-size: contain !important;
  }
  .btnEn .swiper-button-next{
    padding: 2em 2.3em !important;
    background-size: contain !important;
  }
}

@media screen and (min-width: 1700px){
  
  .decoration{
    right:-4vw;
    height: 250px;
  }

  .decoration{
    right:-1vw
  }
  .decorationEn{
    left: -1vw;
  }
  /*

 
  .decoration{
    right:-4vw;
    height: 250px;
  }
  .navbarBottom p{
    font-size: 1.2vw;

  }
  .navbarBottom{
    padding: 2em 1em;
  
  }
  .usrIcn{
    width: 40px;
    height: 40px;
    top: 0;
  }
  .count1::before{
    width: 2vw;
    height:2vw;
    font-size:0.7em;
    top: -1.7em;
  }

  .addressNav p{
    font-size: 1.6vw !important;
  }
  .addressNav span{
    font-size: 1.6vw !important;

  }
  .titleDiv{
margin-top: 6em !important;
  }
  .ordrTypes{
bottom: 18vw;
  }
  .navbarTop svg{
    width: 40px;
    height: 40px;
  }
 
  .navIcon{
    width: 30px;
    height: 30px;
  }
  .navbarTop select{
    font-size: 1.4vw;

  }
  .navbarTop p{
    font-size: 1.4vw;

  }
  .navbarOptions .orderNow{
    font-size: 1.4vw;

  }
  .navbarLogo img{
min-width: 19vw;
  }

  .how{
    font-size: 2.5vw;

  }
  .how-detail{
    font-size: 1.8vw;
    margin-bottom: 1.5em;
  }
  .arrow-button2{
    font-size: 1.4vw;

  }

  .enArrow-button2> .arrow2{
    width: 25px;
    height: 25px;
    border-width: 5px;
  }
  .arrow-button2> .arrow2{
    width: 25px;
    height: 25px;
    border-width: 5px;
  }
  .title-2{
    font-size: 2.5vw;
 
  }
  .title2-2{
    font-size: 1.8vw;
  }
  .certified button{
    font-size: 1.4vw;
    padding:0.7em 0;
  }
  .com-title{
    font-size: 2.5vw;

  }
  .com-details{
    font-size: 1.8vw;

  }
  .title-5{
    font-size: 2.5vw;

  }
  .title2-5{
    font-size: 1.8vw;

  }
  .copyRights p{
    font-size: 1.4vw !important;

  }
  .div5-3 div{
    position: static;

  }
  .title2-5{
    margin-bottom: 1.5em;
  }
  .div5-3{
    display: flex;
    align-items: center;
  }
  .footerVat img{
    min-width:300px !important;
    margin: 0;

  }
  .footerImgs img{
    width: 80px !important;

  } 
  .paymentMethods img{
    width: 60px !important;
  }
  .footerVat{
    display: flex;
align-items: center;
  }
  .footer .container{
max-width: unset !important;
  }
  .thirdDiv {
margin-top: 2em;
  }
.certifyDiv{
  margin: 6em 0;
}
  .div5Buttons div{
    width:220px
  }
  .firstDiv{
    margin-bottom: 3em;
  }
  .thirdDiv{
    padding-bottom: 0 !important;
  }
  .certifiedLogo{
    display: flex;
    align-items: center;
  }
  .btn5{
    height:75px
  }
  .bottomDiv{
   margin-bottom: 0;
  }
  .div5img{
height: 300px !important;
  }
  .appImg{
    height: 800px !important;
  }
 .certifiedLogo .div5img{
    height: 394px !important;
  }
  .decoration{
    right:-1vw
  }
  .decorationEn{
    left: -1vw;
  }
  .certified{
height: 550px;
  }
  .isoHeader{
    font-size: 1.8vw;
  }
  .isoP{
    font-size: 1.6vw;
  }
  .vatTitle{
    font-size: 1.8vw !important;
  }

}


@media screen and (min-width: 1191px){

  .thirdDiv iframe{
   min-height: 43vw;
  }*/
}