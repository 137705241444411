
.navbar{
    padding: 0  !important;
}
.navbarTop{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.usrIcn{
    position: relative;
    top: 5px;
}
.navbarTop select{
    background: none;
    border: none;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
color: #4F4F4F;
cursor: pointer;

}
.navbarTop p{
display: flex;
    font-family: 'Cairo'; 
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
margin-bottom: 0;
cursor: pointer;

}
.navbarTop p:hover{

color: #E47100;
}

.navbarOptions{

    display: flex;
    justify-content: space-between;
    align-items:center;
  margin-top: 1.5em;
 
} 
a{
    color: unset !important;
}
.navbarLogo img{

    background: #420F12;
    border-radius: 0px 0px 20px 20px;
    width: 100%;
    padding: 0.4em 1em;
}
.navbarBottom{
    background: #420F12;
    border-radius: 12px;
    margin-top: 2em;
    padding: 0.8em 3em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.navbarBottom .active p{
   

        color: #E47100;
      
        
  
   

}


.navbarBottom .active path{

    color: #E47100;
    stroke: #E47100;
    
    }

    .nav2Mobile  path{

        color: #4F4F4F;
        stroke: #4F4F4F;
        
        }


.navbarBottom p{

margin-bottom: 0;
cursor: pointer;


font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;

color: white;
}
.hospitable{
position: relative;
bottom: 2px;
}
.navbarBottom p:hover{

    color: #E47100;
  
    }
    .navbarBottom p:hover path{

        color: #E47100;
        stroke: #E47100;
        
        }
   


    .navIcon{
        margin:0 0.5em; 
    }
    .navBorder{
   
        border-left: 1px solid white;
     
    }
    .navBorder1{
        border-left:1px solid #D9D9D9;
        line-height: 20px !important;
        margin: 0 1em;
    }
    .count1{
        position: relative;
    }
    .count1::before{

        content: attr(data-count);
        position: absolute;
        font-weight: 400;
font-size: 12px;
        top:-0.4em;
        left: 1em;
        right: 1.2em;
        
        width:1.2em;
        height: 1.2em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #e47100;
        color: white;
         }
         .count2::before{
            top:-0.7em;
         }
         .navbarOptions .orderNow{
                      font-family: 'Cairo';
         font-style: normal;
         font-weight: 700;
         font-size: 16px;
  
         letter-spacing: -0.3px;
         
         color: #FFFFFF;

         background: #E47100;
    border: none;
         border-radius: 8px;
         padding: 0.4em 1em;
         }
         .navbarOptions .orderNow:hover{

            background: #ec7e11;

         }
         .togg{
             padding: 0;
         }

         .nav2Mobile{
padding: 0 1em;

right: -3em;
         }
         .nav2Mobile select{
             display: block;
             margin-bottom: 0.5em;
             font-family: 'Cairo';
             font-style: normal;
             font-weight: 700;
             font-size: 13px;
         }

         .nav2Mobile p{
        
            cursor: pointer;
            margin-bottom: 0.5em;
            
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 30px;
            letter-spacing: -0.3px;
            
            color: #4F4F4F;
       
           
                     }
                     .count1{
                         margin: 0 0.3em;
                     }
     
                     .nav2Mobile p:hover{

                        color: #E47100!important;
                        background-color: #e4720013;
                     }

                     .active p{
                        color: #E47100!important;
                      
                    }

                    .nav2Mobile p:hover path{ 
stroke: #E47100;
                        color: #E47100!important;
                     }

                     .active path{
                         stroke: #E47100;
                        color: #E47100!important;
                      
                    }
         @media screen and (max-width: 1050px){
           
        .navbarBottom span{
            display: none;
        }
        .remove{
            display: none;
        }
        }


@media screen and (max-width: 900px){
    .navbarBottom {
        display: none;
    }
    .links{
        display: none;
       
    }
    .togg{
        display: inline !important;
    }
   
 .navbarOptions select{
    display: none;
 }
.mobileNavRemove{
    display: none !important;
}
}

@media screen and (max-width: 600px){
    .navbarTop {
       flex-direction: column;
    }

.navbarLogo img {

    max-width: 200px;

}

}

