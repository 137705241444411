.cstmr{
    
    display: flex;
  
    margin-top: 3%;
}

.cstmr p{
    display: inline;
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 27px;
display: flex;
align-items: center;

letter-spacing: -0.3px;
color: #4F4F4F;
cursor: pointer;
margin-bottom: 0;
padding-bottom: 10px;
padding: 0.3em 1em;
border-bottom: 2px solid transparent;
}
.videoAlign{
    position: relative;
  
    transform: translateY(10%);

}
.videoAlign video{margin-bottom: 7em;}
.cstmr .p1{
    margin-right: 5%;

 
}

.cstmr p:hover{
    color: #E47100;
    border-style: none none solid none ;
    border-color: #E47100 ;
    border-width: 2px;
    background: #FFFFFF;
border-radius: 8px 9px 0px 0px;
}

.Customeractive{
    color: #E47100 !important;
    border-style: none none solid none ;
    border-color: #E47100 !important;
    border-width: 2px;
    background: #FFFFFF;
border-radius: 8px 9px 0px 0px;
}

.cstmhr{
    margin: 0;
}

.perks{
 text-align: start;
  
}

.perks img{
    float: right;
    margin-left: 10px;
    margin-bottom: 20px;
}

.top{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;

    letter-spacing: -0.3px;
    text-align: start;
    color: #000000;
    margin-bottom: 2rem;
}

.pnt{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    /* identical to box height */
    
    text-align: start;
    letter-spacing: -0.3px;
    
    color: #1B0D00;
    

}
.topCstmIcn img{
    float: left !important;
    margin-left: 0 !important;
    margin-right: 10px !important;
}

.bottom{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 37px;
text-align: start;
letter-spacing: -0.3px;

color: #000000;
margin-bottom: 40px;
}


    ul li{
        font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 37px;
text-align: start;
letter-spacing: -0.3px;

color: #505050;
      
        margin-bottom: 1rem;
    }

    .first{
        margin-bottom: 1rem; 
    }

    @media screen and (max-width: 991px) {
        .videoAlign {
            transform: translateY(0);
            margin-bottom: 3em;
        }
      }

      @media screen and (max-width: 500px) {
        .cstmr .p1{
           font-size: 14px;
        }
        .top{
            font-size: 14px; 
            line-height: normal;
        }
        .bottom{
            font-size: 14px; 
        }
        .pnt{
            font-size: 13px;   
        }
        .cstmr p{
            font-size: 14px !important;    
        }
        .tandc1{
            font-size: 14px;
            line-height: normal; 
        }
        .tandc2{
            font-size: 14px; 
            line-height: normal;
        }
        .tandc3{
            font-size: 13px;
            line-height: normal;
        }
        ul li{
            font-size: 13px; 
                line-height: normal;
        }
      }

      @media screen and (min-width: 1700px){/*

        .cstmr p{
            font-size: 1.8vw;
           
        } 
        .top{
            font-size: 1.4vw;

        }
        .pnt{
            font-size: 1.2vw;
         
        }
       .perks div{
           margin-bottom: 2em;
       }
        .perks img{
            height: 2vw;
            margin-bottom: 0;

        }
        .bottom{
            font-size: 1.4vw; 
            margin-bottom: 5em; 
        }
        .cstmr p:hover{
            border-width: 6px;
          
        }
        .cstmr p{
            padding-bottom: 1em;
            border-width: 6px;

        }
        .Customeractive{
            border-width: 6px;

        }
        .tandc1{
            font-size: 1.4vw;  
        }

        .tandc2{
            font-size: 1.6vw;  
        }
        .tandc3{
            font-size: 1.4vw;  
        }
        ul li{
            font-size: 1.2vw;  
line-height: normal;
        }*/
      }