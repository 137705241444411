.spNav{
   
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 34px;

letter-spacing: -0.3px;
display: flex;
color: #4F4F4F;
} 

.spNav span{
    color: #828282;
}

.spImage .pImage{
    width: 100%;
height: 35vw;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}
.spDetailContainer{
background-color: white;
width: 100%;
  position: absolute;
  bottom: 0;
}

.spDetail{
  display: flex;
  justify-content: space-between;
  background: rgba(228, 113, 0, 0.06);
  align-items: center;
  padding: 1em;
  
}

.spImage {
 position: relative;
display: flex;
align-items: center;
}

.spFlex{
    display: flex;
    justify-content: space-between;
  
}
.sp-p1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 45px;
letter-spacing: -0.3px;

color: #E47100;
}

.sp-p2{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 27px;
letter-spacing: -0.3px;
color: #331900;
margin-bottom: 0.5em;
}

.sp-p3{
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;
color: #828282;
max-width: 390px;
}

.sp-p4{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    letter-spacing: -0.3px;
    color: #828282;
    display: inline-block;
}

.sp-p5{
  
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;
color: #331900;
    display: inline-block;
    padding: 0 1em;
}
.spQuantityEn   .spQuantity-btn2 {
  border-radius: 0px 10px 10px 0px;
}
.spQuantityEn   .spQuantity-btn1 {
  border-radius: 10px 0px 0px 10px;
}

.accordion-body label{
    font-family: 'Cairo' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 41px !important;
  text-align: right !important;
  letter-spacing: -0.3px !important;
  
  color: #828282 !important;
 padding-right: 0.5em;
  }
  .accordion-body .enadditional{
  padding-left: 0.5em ;
  padding-right: 0 !important;
  }
  .accordion-body .flex{
display: flex;
justify-content: space-between;
align-items: center;
  }

  .accordion-body .flex p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 49px;

    letter-spacing: -0.3px;
    
    color: #E47100;
    margin-bottom: 0;
      }
  
  .accordion-body input[type="radio"]{
    cursor: pointer !important;
    width: 20px;
    height: 20px;
   
  }
  input[type="radio"] {
	accent-color: #E47100 ;
}
input[type="checkbox"] {
	accent-color: #E47100 ;
    cursor: pointer;
}
.accordion-body .encol{
     text-align: left !important; 
     direction: ltr !important;
}
.accordion-body .encol2{
    text-align: left !important; 
   
}

.accordion-body .encol label{
   padding-left: 0.5em;
}
.accordion-body .encol2 label{
    padding-left: 0.5em;
 }

 .enrow{
     direction: ltr !important;
 }
 .addAlert span{
  color: #633235;
  font-size: 20px;
}  
.vAlignText{

  position: relative;
  top: 50%;
  transform: translateY(-50%);
 
}
.addAlert p{

  color: white;

 
  font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
margin-bottom: 0.5em;
border-radius: 10px;
line-height: 20px;
text-align: initial;

}
.alertImg{


  height: 100px;
 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
.alert-hidden {
   opacity: 0;
  display: none !important;
  position: absolute;
 
  }

  .addAlert{
    text-align: center;
    transition: all 1s  ease-in;
    background-color: #e47100;
 
position: absolute;
left: 0; 
right: 0; 
margin-left: auto; 
margin-right: auto; 
bottom: 150px;
width: 29em;
max-width: 90vw;
padding: 0.5em;
box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.05);
border-radius: 10px;

}
 
 .accordion-button{
 
    font-family: 'Cairo' !important;
   font-style: normal !important;
   font-weight: 600 !important;
   font-size: 24px !important;
   line-height: 45px !important;
   letter-spacing: -0.3px !important;
   
   color: #633235 !important;

   border: none!important; 
   
   }
   .accordion-button div{
   
   width: 100%;
     display: flex !important;
   justify-content: space-between !important;
   }
   
   .accordion-button:focus {
    border: none!important; 
   
   
     outline: none !important;
     box-shadow: 0 0 0 0 #E0E0E0  !important;
   }
   
   
   
   .accordion-item {
     border: none !important;
     margin-top: 1%;
   }

   
   .accordion-body {
     text-align: right !important;
   }
   
   
   .accordion-button:not(.collapsed) {
   
     background-color: transparent !important; 
     border: none !important;
    
   }
   .accordion-item {
     border: 1px solid #E0E0E0 !important;
     border-left: none !important;
     border-right: none !important;
     border-top: none !important;
     border-radius: 0 !important;
     margin-bottom: 1em;
   }
   
   
    .collapsed{
  
     border: none !important;
   }
   .accordion-button .rotate{
     transition:transform 0.5s;
     color: #E47100;
   }
   
   .collapsed .rotate{
     transform-origin: 50% 50%;
     transform: rotate(-180deg);
     transition:transform 0.5s;
   
     color: #633235 !important;
   }
   .accordion-button::after {
       background-image: none !important;
   }

   .spAdd{
    background: #E47100;
    border-radius: 10px;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.3px;
display: flex;
align-items: center;
justify-content: space-between;
    width: 50%;
    color: #FFFFFF;
    padding: 0.7em 1em;
    border: none;

   
   } 
.spAdd:disabled{
cursor: not-allowed;
background-color: #eeb378;
}
.mmMenuItems button:disabled{
  cursor: not-allowed;
  background-color: #eeb378; 
}

   .spData p{

  
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;

margin-bottom: 0.5em;
letter-spacing: -0.3px;


color: #633235;
  }
  .spData{
margin-bottom: 1em;
    border-bottom: 0.5px solid #C9C9C9;
    padding-bottom: 1em;
  }

  .spData input{
cursor: pointer;

  }

.spData label{
  font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
margin: 0 0.5em;
letter-spacing: -0.3px;

color: #4F4F4F;

}

.spCategories .mmSideNavEn{
  border:none;
   }

 .spCategories .mmsideNav-link{
    display: inline-block;
   
  }
.spCategories .mmSideNav{
  border: none;
}
.spCategories .mmsideNav-link img{
  margin: 0;
  float: none;
}
.spCategories .mmsideNav-link p {

  text-align: center;
  white-space: nowrap;
}
.spCategories  .mmSideNavEn .mmsideNav-link {
  border-left: none !important;
  margin-right: 2em;
 
}
.spCategories .mmSideNavEn .mmsideNav-link img{
  margin: 0;
  float: none !important;
}
.spCategories  .mmSideNavEn .mmsideNav-link {
  text-align: center;
  white-space: nowrap;
  padding: 0.5em;
  border-radius: 0;
}
.spCategories .mmsideNav-title{
  margin-bottom: 0.5em;
}
.spCategories .categoryScroll{
  display: flex;
flex-direction: row;

overflow-x: auto;
} 

.spCategories .mmSideNav .mmsideNav-link {
  border-right: none !important;
  margin-left: 2em !important;
  text-align: center;
  border-radius: 0 !important;
  border-bottom: 5px solid transparent ;
  padding: 0.5em;
}

.spCategories .mmsideNav-link:hover {
  border-bottom: 5px solid #E47100 !important;

}
.spCategories .mmsideNav-linkActive {
  border-bottom: 5px solid #E47100 !important;
  border-radius: 0 !important;

}
.btnTitle{
  font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;
display: block;
letter-spacing: -0.3px;



color: #633235;
}

   .spAdd span{
    font-weight: 500;
    font-size: 20px;
    line-height: 37px;
   }
   .spAdd:hover{
    background: #f78411;}

  .spQuantity{
       display: inline-block;
       font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 20px;
color: #4F4F4F;
   }
 
.spButtons{
   
    margin-top: 0.5em;
text-align: center;
display: flex;
justify-content: space-between;
}

.spQuantity-btn1{
    border: 1px solid #C9C9C9;
    border-radius: 0px 10px 10px 0px;
background-color: transparent;
padding: 0.7em 1.5em;
}
.spQuantity-btn2{
    border: 1px solid #C9C9C9;
    border-radius: 10px 0px 0px 10px;
 
background-color: transparent;
padding: 0.7em 1.5em
}

.spQuantity-btn1:hover{

    box-shadow: 5px 0px 17px rgba(0, 0, 0, 0.15);
}
.spQuantity-btn2:hover{

    box-shadow: 5px 0px 17px rgba(0, 0, 0, 0.15);
}

.spRecomend{
   
    margin-bottom: 100px;
    margin-top: 50px;
}
.spRecomend p{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;

  letter-spacing: -0.3px;
  
  color: #DB095B;
  
 
}
.spRecomend .swiper{
 height: 18em !important;
 align-items: center;
 overflow: initial;
 
}
.spRecomend {
  overflow-x: clip;
}


.menu-recommendations div{
display: inline-block;
height: 6em;
width: 50%;
background-position: center;
background-repeat: none;
background-size: cover;
}

.menu-recommendations{

  box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
border-radius: 14px;
text-align: center;
padding: 1em;
}

.spRecomend-scroller{
    background-color: transparent;
    overflow: auto;
    white-space: nowrap;
    margin: 1rem 0;
margin-bottom: 0;
   align-items: flex-start;
   direction: rtl;
  height: 100%;
  padding-bottom: 1em;
}
.BscrollTitle{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
  text-align: right;
    letter-spacing: -0.3px;

    color: #4F4F4F;
    margin-bottom: 1em;
  }
  div.spRecomend-scroller .Div {
    display: inline-flex;
    background: #F3F3F3;
    border-radius: 15px;
    text-align: center;
    padding: 10px;
    margin-left: 50px;
    text-decoration: none;
    height: 120px;
    width: 17em;
    flex-wrap:nowrap;
    justify-content: space-between;
    align-content: center;
    cursor: pointer;
    direction: ltr;
  }

  div.spRecomend-scroller img {
   height: auto;
   width: 40%;
   float:right;
   filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));
border-radius: 8px;

  }

  div.spRecomend-scroller .BscrollP{
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
margin: 0 15px;
  }

  div.spRecomend-scroller .bsP-1{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
margin-bottom: 0;
text-align: right;
letter-spacing: -0.3px;
display: block;
/* #331900 */

color: #331900;
cursor: default;

   }

   div.spRecomend-scroller .bsP-2{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    /* identical to box height */
  
    text-align: right;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    color: #E47100;
    cursor: default;
   }


  .arrow-button {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size:calc(16px + 0.5vw);

    line-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.3px; 
    color: #331900;
    display: flex;
    background-color: transparent;
    padding: 0;
    transition: all .3s ease;
    cursor: pointer;
    border: none;
    width: 3em;

border-radius: 0 0 16px 16px;

}
.arrow-button:hover{
background-color: transparent !important;

}

.arrow-button > .arrow {
    width: 13px;
    height: 13px;
    border-right: 3px solid  #331900;
    border-bottom: 3px solid  #331900;
    position: relative;
    transform: rotate(-220deg);

    transition: all .3s ease;
   margin: 0 10px;
}
.calories{
  display: flex;
  justify-content: space-between;
}
.calories p{
  margin-bottom: 0 !important;
}
.totalCal{
  color: #DB095B !important;
  font-family: 'Cairo';
font-weight: 400 !important;
}

.Div:hover .arrow-button > .arrow {
    transform: rotate(-220deg) translate(4px, 4px);
    border-color: text-hover-color;
}

.Div:hover .arrow-button > .arrow::before {
    opacity: 1;
    width: 8px;
}

.Div:hover .arrow-button {

    border-radius: 0 0 16px 16px;
}

.recommend-name{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 31px;

  letter-spacing: -0.3px;
 
  margin-bottom: 0;
  color: #331900 !important;
}
.recommend-price{

  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 34px;

  letter-spacing: -0.3px;
  
  color: #E47100 !important;
  

}
.swiper-button-prev.swiper-button-disabled {
display: none;

}

.swiper-button-next.swiper-button-disabled {
  display: none;
  
  }





.recommmendAr .swiper-button-next {
  background-image: url("../Images/small-left.svg") !important;
  background-repeat: no-repeat;
  background-color: #FFFFFF;


box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
 
  background-position: center;
  border-radius: 50%;
  padding: 1em 1.3em;
}
.recommmendAr  .swiper-button-next:hover {

  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
 
}
.recommmendAr   .swiper-button-next:after, .myCarousel .swiper-rtl .swiper-button-next:after {
  content: '' !important;
}


.recommmendAr .swiper-button-prev {
  background-image: url("../Images/small-right.svg") !important;
  background-repeat: no-repeat;
  background-color: #FFFFFF;


box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
 
  background-position: center;
  border-radius: 50%;
  padding: 1em 1.3em;
}
.recommmendAr  .swiper-button-prev:hover {

  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
 
}
.recommmendAr   .swiper-button-prev:after, .myCarousel .swiper-rtl .swiper-button-next:after {
  content: '' !important;
}


.recommmendEn .swiper-button-prev {
  background-image: url("../Images/small-left.svg") !important;
  background-repeat: no-repeat;
  background-color: #FFFFFF;


box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
 
  background-position: center;
  border-radius: 50%;
  padding: 1em 1.3em;
}
.recommmendEn  .swiper-button-prev:hover {

  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
 
}
.recommmendEn   .swiper-button-prev:after, .myCarousel .swiper-rtl .swiper-button-next:after {
  content: '' !important;
}



.recommmendEn .swiper-button-next {
  background-image: url("../Images/small-right.svg") !important;
  background-repeat: no-repeat;
  background-color: #FFFFFF;


box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.14);
 
  background-position: center;
  border-radius: 50%;
  padding: 1em 1.3em;
}
.recommmendEn  .swiper-button-next:hover {

  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.24);
 
}
.recommmendEn   .swiper-button-next:after, .myCarousel .swiper-rtl .swiper-button-next:after {
  content: '' !important;
}



.medyafMenu .bckg{
  width: 100%;
  border-radius: 8px;
  height: 10em;
  background-size: cover;
  background-repeat: no-repeat;
background-position: top center;
}

.medyafMenu {
  text-align: center;
  background: white;
border-radius: 12px;
min-width: 236px;


}
.reccomendation {

box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
padding: 1em;
padding-bottom: 0;

}

.medyafMenu button{
   background: #E47100;
   border-radius: 10px;
   font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 34px;
letter-spacing: -0.3px;
color: #FFFFFF;
border: none;
width: 100%;
padding: 0.2em;

}

.medyafMenu button:hover{
  background: #f1861c;}


.medyafMenu p{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 34px;

  letter-spacing: -0.3px;

  /* #331900 */
  
  color: #E47100;
}
.additionals{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
  align-items: center;
}
.additionals p{
font-size: 18px;
font-weight: 500;
  margin-bottom: 0;

}
@media screen and (max-width: 991px){

  .spImage .pImage{
    height: 27em;
}
.spDetailContainer{
  bottom: 0;
}
}
 
@media screen and (max-width: 500px){

 
  .spNav{
    font-size: 14px;
  }
  .sp-p1{
    font-size: 14px;
  }
  .sp-p2{
    font-size: 14px;
  }
  .sp-p3{
    font-size: 13px;
  }
  .spDetail{
padding-bottom: 0;

  }
.spImage{
margin-bottom: 2em;
  }
  .spImage .pImage{
    height: 23em;
  }
  .spData p{
    font-size: 14px;
  }
  .spData label{
    font-size: 13px;
  }
  .btnTitle{
    font-size: 14px;
  }
  .spAdd{
    font-size: 14px;
    margin-top: 2em !important;
  }
  .spAdd span{
    font-size: 14px;
  }
  .spQuantity p{
    font-size: 14px;
  
  }
  .btnTitle{
    margin-bottom: 0.5em;
  }
  .spQuantity-btn1{
    font-size: 14px;
  }
  .spQuantity-btn2{
    font-size: 14px;
  }
  .addAlert span{
    font-size: 14px;
  }
  .addAlert p{
    font-size: 14px;
  }
  .alertImg{
    height: 80px;
  }
  .step1Ar div{
    font-size: 14px !important;
  }
  .step1 div{
    font-size: 14px !important;
  }
  .stepAr div{
    font-size: 14px !important;
    position: relative;
  
  }
  .step div{
    font-size: 14px !important;
    position: relative;
    top: 8px;
  }
  .step4Ar div{
    font-size: 14px !important;
    position: relative;
  
  }
  .step4 div{
    font-size: 14px !important;
    position: relative;
    top: 8px;
  }
  .cpTop svg{
  margin-bottom: 0!important;
  }
  .cpMiddle-title p{
    font-size: 14px;
  }
  .cpMiddle-title button{
    font-size: 14px;
  }
  .cpMiddle-p1{
    font-size: 14px;
    margin-bottom: 0;
  }
  .cpMiddle-p2{
    font-size: 13px;
  }
  .bPrice{
    font-size: 14px !important;
  }
  .cpMiddle-center button{
    width: 25px;
    height: 25px;
  }
  .basketItemImg{
    top: 40% !important;
  }
  .cpBottom-p1 {
    font-size: 14px;
  }
  .cpBottom-p2{
    font-size: 14px;
  }
  .cpBottom-p3{
    font-size: 13px;
  }
  .cartNext button{
    font-size: 14px;
    width:133px !important
  }
  .cpMiddle-title2{
    font-size: 14px;
  }
  .cpForm{
    font-size: 14px;
  }
  .cpRadio label{
    font-size: 13px;
  }
  .cpPayment-div1-title{
    font-size: 14px !important;
  }
  .cpMoney{
    font-size: 13px !important;
  }
  .cpMoneyBreakdown p{
    font-size: 14px;
    margin-bottom: 0;  
  }
  .cpTotalP{
    font-size: 14px;
  }
  .cpTotal{
    font-size: 14px;
  }
  .cpCurrency{
    font-size: 13px;
    line-height: 0;
  }
  .cpPayment-div2 label{
    font-size: 14px;
  }
  .cpPayment-div2 span{
    font-size: 13px;
  }
  .cpPayment-div2 input[type="radio"]{
    width: 1em;
    height: 1em;
  }
  .cpPayment-div2 p{
    font-size: 14px;
  }
  .cpPayment-div2 img{
height: 270px;
  }
  .paymentDone-p1{
    font-size: 16px;
    line-height: normal;
    margin-bottom: 0;
  }
  .paymentDone-p2{
    font-size: 14px;
  }
  .cpPaymentDone button{
    font-size: 14px;
  }
}


.medyafDetails p{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.3px;

  /* Gray 3 */
  
  color: #828282;
 
}

.medyafDetails{
  display: flex;
  justify-content: space-between;
}

  .spQuantity p{
    display: inline-block;
    border: 1px solid #C9C9C9;
    padding: 0.7em 1.5em;
    border-style: solid none solid none;
    margin-bottom: 0;
  }



  .spNav span{
    margin:0 0.2em;
  }

  
  @media screen and (max-width: 500px) {
   
  .spAdd{
    margin: 1em 0;
    width: 100%;
  }
  .spButtons{
    flex-direction: column;
    align-items: flex-start;
  }
  .sp-p2 {
font-weight: 600;
  }
  .sp-p1 {
    font-weight: 600;
      }
  }


  .invoiveLogo{
    width: 100%;
    height: 5.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  
  }
  .invoiceData div{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .invoiceData{
    padding: 1em 0;
    border-bottom: 1px solid #E0E0E0;
}
  
  .invoiceData p{
font-family: 'Cairo';
font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;
    margin-bottom: 0.2em;
    letter-spacing: -0.3px;
    color: #4F4F4F;
  }

  .invoiceData .invoice-value {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 34px;
    letter-spacing: -0.3px;
    color: #331900;
}
  .invoiceTitle{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 52px;
    letter-spacing: -0.3px;
    color: #331900;
    margin-bottom: 0;
  }
 
  .invoiceTable th{
    text-align: start !important;
  }
  .invoiceTableDiv{
    box-shadow: none !important;
    padding-bottom: 1em;
    border-bottom: 1px solid #E0E0E0;
    border-radius: 0 !important;
  }
 .invoiceTotal1{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px;
  letter-spacing: -0.3px;
  color: #331900 !important;

}
.invoiceTotal2{

  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: -0.3px;
  color: #331900 !important;
  
}
.caution{
  text-align: start;
  padding-top: 1em ;
}
.invoiceDiv{
  max-width: 500px !important;
}
.invoiceTableDiv .table{
  min-width: 0 !important; 
}

@media screen and (max-width: 500px) {
   
  .invoiceTitle{
    font-size: 20px;
    line-height: 0;
    margin-bottom: 1em;
  }
.invoiceData p{
  font-size: 14px;
}
.invoiceData .invoice-value{
  font-size: 13px;
}
.invoiceTotal1{
  font-size: 15px !important;
  line-height:25px;
  margin-bottom: 0;
}
.invoiceTotal2{
  font-size: 18px !important;

}
.invoiceTable{
  min-width: 0 !important;
}
.invoiceDiv{
  padding: 15px !important;
}
}

@media screen and (min-width: 1700px){/*
  .recommend-name{
    font-size:1.7vw !important
  }
  .medyafMenu p{
    font-size:1.4vw !important
  }
  .spRecomend{
    height: 27vw;
  }
  .addAlert p{
    font-size:1.4vw 
  }
  .addAlert span{
    font-size:1.5vw 

  }
  .alertImg{
    height: 16vh;
  }
  .addAlert{
    width: 38vw;
  }
  .orderSuccess p{
    font-size:2vw 
  }
  .orderSuccess{
    margin-bottom: 23vh !important;
  }
  .orderSuccess img{
  height: 10vw;
  }
  .shareForm button{
    font-size:1.4vw !important 
  }
  .invoiceTitle{
    font-size: 2vw;
  }
  .invoiveLogo{
    height: 7vw;
  }
  .invoiceData p{
    font-size: 1.4vw;
  }
  .invoiceData .invoice-value{
    font-size: 1.4vw;
  }
  .invoiceDiv{
    max-width: 37vw !important;
  }
  .invoiceTotal2{
    font-size: 1.5vw !important;
  }
  .invoiceTotal1{
    font-size: 1.5vw !important;
  }*/

}