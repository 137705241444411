.mainMenu{
    display: flex;
  
    justify-content: center;
    text-align: center;
    padding: 2em 0;
}
.mmItem{
    flex:3;
    padding-right: 20px;
}
.mmFlex{
    display: flex;
    justify-content: space-between;
}
.invoiceSecondTitle{
  font-family: 'Cairo';
  color: #616060;
  font-weight: 600;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 1em;

}
.invoiceSecondTitle p{
  margin-bottom: 5px;
}
.mmFlex input{
    background: #F6F6F6;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;
color: #828282;
border: none;
padding: 0.5em 1.5em;
padding-right: 50px;
}
.mmFlex input::placeholder{

  font-size: 16px;

}
.mmFlex div{
    position: relative;
}
.mmFlex .mmIcon{
    position: absolute;
    color: #828282;
   top: 10px;
   right: 8px;
} 
.mmIconEn{
  left: 8px;
  right: auto;
}
.mmFlexEn input{
  padding: 0.5em 1.5em;
    padding-left: 50px;
}
.mmSideNav{
    flex:1;
    text-align: right;
    border-bottom: 0.5px solid #BEBEBE;
    border-left: 0.5px solid #BEBEBE;
    padding-left: 20px;

}
.mmSideNavEn{
  flex:1;
text-align: left;
  border-bottom: 0.5px solid #BEBEBE;
  border-right: 0.5px solid #BEBEBE;
  padding-right: 20px;

}
.noProduct{
  color: #828282;
  font-family: "Cairo";
  font-size: 20px !important;
  position: relative;
top: 100px;
height: 50vh;
}
.mmSideNavEn .mmsideNav-linkActive {

  border-left: 5px solid #E47100 !important;
  border-right: none;
}

.mmSideNavEn .mmsideNav-link:hover {

  border-left: 5px solid #E47100;
  border-right: none;
  border-radius: 0px 8px  8px 0;
}
.mmSideNavEn img{
  
 float: left !important;
}
.spImage{
  display: flex;
  align-items: flex-start !important;
}

.mmItem-title{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;

    letter-spacing: -0.3px;
    color: #331900;
    margin-bottom: 1.5em;
}
.mmsideNav-title{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;


letter-spacing: -0.3px;
color: #331900;
margin-bottom: 1.5em;
}
.mmsideNav-link p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
 
    letter-spacing: -0.3px;
 
    color: #331900;
    margin-bottom: 0;
}


.mmsideNav-link {
  border-right: 5px solid transparent;
}

.mmSideNavEn .mmsideNav-link {
  border-right: none;
  border-left: 5px solid transparent;
  border-radius: 0px 8px 8px 0px;
}

.mmsideNav-linkActive{

  background: rgba(228, 113, 0, 0.07);
  border-radius: 8px 0px 0px 8px;
 
  border-right: 5px solid #E47100;
}
.mmsideNav-link img{
  float: right;
  border-radius: 100%;
  margin: 0 15px;
  width: 36px;
height: 36px;
position: relative;
bottom:5px
}

.menuImage{
  margin: 0.2em 0;
  height: 24vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 1em 0;
}

.mmsideNav-link:hover{
    background: rgba(228, 113, 0, 0.07);
    border-radius: 8px 0px 0px 8px;
   
    border-right: 5px solid #E47100;
  }

  .mmsideNav-link{
 cursor: pointer;
 padding: 10px 0;
 margin-bottom: 0.5em;
  }

  .mmMenuItems{
    background: rgba(190, 190, 190, 0.09);
border-radius: 12px;

  }
  .mmMenuItems .mmName{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
  
    letter-spacing: -0.3px;
    color: #331900;
margin-bottom: 0;
  }
  .mmMenuItems .mmDescription{
  
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 27px;
text-align: center;
letter-spacing: -0.3px;
height: 3.5em;
/* Gray 3 */

color: #828282;

overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
  }

  .mmMenuItems img{
  
  width: 90%;
  height: auto;
      }
  .mmMenuItems .mmPrice{
  
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 37px;
   
    letter-spacing: -0.3px;
    
    /* #633235 */
    
    color: #633235;
      }
      .mmMenuItems button{
  
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
        letter-spacing: -0.3px;
        color: #FFFFFF;
        background: #E47100;
border-radius: 10px;
width: 100%;
height: 50px;
border: none;
          }
          .mmMenuItems button:hover{
  
            background: #fc8712;
  
              }
              #overF{
display: none;
              }

              @media screen and (max-width: 900px){
           
                .mmSideNavEn-mobile{
                   border: none;
                }
                .mmsideNav-link{
                  display: inline-block;
                 
                }
                .mmsideNav-link p {

                  text-align: center;
                }
                .mmSideNavEn .mmsideNav-link {
                  border-left: none;
                  margin-right: 2em;
                }
                .categoryScroll{
                  display: flex;
    flex-direction: row;
  
    overflow-x: auto;
                }




.mmsideNav-linkActive{

border-bottom: 5px solid #E47100;
border-radius: 0 !important;
}
.mmSideNavEn .mmsideNav-linkActive {

  border-left: none !important;

}
.mmSideNavEn .mmsideNav-link:hover {

  border-left: none !important;
  border-bottom: 5px solid #E47100;
  border-radius: 0 !important;
}

.mmSideNav{

  border: none;
}
#overF{
  display: block;
  margin-bottom: 1em;
  text-align: end;
  color: #E47100;
                }

.mmSideNav .mmsideNav-link:hover {

  border-right: none !important;
  border-bottom: 5px solid #E47100;
  border-radius: 0 !important;
}

.mmsideNav-linkActive{

  border-bottom: 5px solid #E47100;
  border-radius: 0 !important;
  }

  .mmMenuItems .iVGwIn{
 height:24vh !important;

  }

  .mmSideNav .mmsideNav-link {
    border-right: none;
    margin-left: 2em;
  }
                .mainMenu {
                  flex-direction: column;
                }
                } 

                @media screen and (max-width: 450px){
           
                  .mmFlex{
                 flex-direction: column;
                 align-items: start;
                  }
                
                
                  .mmItem-title{
margin-bottom: 0;
font-weight: 600;
                  }
                  .mmFlex input{
                    margin-bottom: 1em; 
                  }
                }

                @media screen and (max-width: 500px){

                  .mmsideNav-title{
font-size: 16px;
                  }
                  .mmsideNav-link p {
                    font-size: 13px;  
                  }
                  .mmItem-title{
                    margin-bottom: 1em;
                    font-size: 16px;  
                  }
                  .mmMenuItems .mmName{
                    font-size: 14px;  
                  }
                  .mmDescription{
                    font-size: 13px; 
                  }
                  .mmFlex input{
                    margin-bottom: 3em;
                  }
                  .mmPrice{
                    font-size: 14px !important; 
                  }
                   input{/*
                    font-size: 13px !important; 
                 */ }
                  .mmMenuItems button{
                    font-size: 14px; 
                  }
                }
         