.pExch{

background: #FFFFFF;
box-shadow: 4px 5px 30px rgba(0, 0, 0, 0.1);
border-radius: 12px;
padding: 5%;
display: inline-block;
text-align: right;
width: 100%;

}
.exCredit{

font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 41px;

letter-spacing: -0.3px;

/* #331900 */

color: #331900;

}
.exMid{
    display: flex;
    justify-content: space-between;
    margin:5% auto;
}
.exBox{
    border: 1px solid #E0E0E0;
    border-radius: 10px;
  
  width: 13rem;
  justify-content: center;
    align-items: center;
display: inline-flex;
}
.exBox .exCurrency{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 37px;
    letter-spacing: -0.3px;
    color: #633235;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 0.5em;
}
.exBox .currencyValue{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 60px;
letter-spacing: -0.3px;
color: #E47100;
display: inline-block;
margin-bottom: 0;
}

.pExch button{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 34px;
letter-spacing: -0.3px;
color: #FFFFFF;
background-color: #E47100;
border: none;
display: block;
border-radius: 10px;
width: 100%;
text-align: center;
height: 64px;
}

.pExch button:hover{
    background-color: #ff9225;
}
.exMid .rotate{

   transition: transform 1.2s;
   transform: rotate(360deg);
  margin: 0.5em 0.5em;
}
.exMid .rotate2{
    transition: transform 1.2s;
    transform: rotate(-360deg);
    margin: 0.5em 0.5em;
 }