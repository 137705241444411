

.addressNav p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
  
    letter-spacing: -0.3px;
    
    color: #E47100;
}
.autoComplete{
    position: relative;
}
.autoComplete .mapIcn{
    position: absolute;
    left: 18px;
    bottom: 10px;
}
.autoComplete .mapIcnEn{
    position: absolute;
    left: auto !important;
 right: 18px;
}
.addressNav span{
    font-weight: 600;
    font-size: 20px;
    line-height: 45px;

    letter-spacing: -0.3px;
    
    color: #4F4F4F;
}

.addressBody{
    background: #F4F4F4;
border-radius: 12px;
padding: 3em;
margin-bottom: 5em;
}
.addressInput input{
 
    background: #FFFFFF;
border-radius: 8px;
width: 100%;
border: none;
padding: 0.6em 0.5em;

}
.addressInput input:disabled {
    cursor: not-allowed;
  }
 
.css-6j8wv5-Input {


    caret-color: transparent !important;
}

.select-placeholder-text{
    font-family: 'Cairo'!important;
    color: #ACACAC;
   
}
.modal-p{

    font-family: 'Cairo';
    position: relative;
    top: 40%;
    color: #633235;
    font-weight: 600;
    font-size: 20px;
}
.addressCity{
    font-family: 'Cairo';
    color: #ACACAC;
    background: #FFFFFF;
    border-radius: 8px;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    
    letter-spacing: -0.3px;
    

    border: none;
    padding: 0.3em 10px;
    width: 100%;
}


.addressInput input:focus{
   border: 2px solid #E47100;
   outline: none;
}
.addressInput span{
   color: brown;
   font-family: 'Cairo';
}

.noAddress{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #ACACAC;
}
.addressInput button{


background-color: #E47100;
border: none;
color: white;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 37px;
letter-spacing: -0.3px;
width: 100%;
padding: 0.1em 0;
border-radius: 8px;
}


.addressInput button:disabled {
    background: #dddddd;
  }
  .addressInput button:disabled:hover{

    background: #dddddd;
}

.addressInput button:hover{

    background-color: #f68616;
}
.addressInput ::placeholder{

    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #ACACAC;

}
.addressInput label{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 41px;
letter-spacing: -0.3px;

color: #4F4F4F;
margin-bottom: 0.5em;
}
.backBtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
}
#back{
    display: flex;
    align-items: center;
}
#back p{
    display: inline-block;
    margin: 0 0.7em;
    padding-bottom: 0;
}
#back svg{
    position: relative;
    transition: transform 0.8s;
}
#back button{
    min-width: unset !important;
}
.backEn button:hover svg{
    transform: translate(-10px,0) !important;
}
#back button:hover svg{
    transform: translate(10px,0);
}
.addressTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 41px;

letter-spacing: -0.3px;

color: #633235;
margin-top: 0;
}
.addressNotes{
padding-bottom: 3em !important;
}
.addressNav{
    display: flex;
    margin-top: 3%;
}
.addressNav p {
    display: inline;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  
    letter-spacing: -0.3px;
    color: #E47100;
  
    margin-bottom: 0;
    padding-bottom: 10px;
   }
    .addressNav span {
        color: #4F4F4F;
        font-size: 20px !important;
  
    }
.myAddress{
    background-color: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
border-radius: 8px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 1em;
}
.addressName{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 37px;

letter-spacing: -0.3px;

color: #4F4F4F;
margin-bottom: 0em;
}

.location{
font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 30px;
text-align: center;
letter-spacing: -0.3px;
display: inline;
color: #4F4F4F;
width: 70%;
}

.chooseAddress button{
    border: none;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
background: #E47100;
border-radius: 8px;
padding: 0 1.5em !important;
}

.vAlign{
    display: flex;
    align-content: center;
    justify-content: center;
  
}
.vAlign img{
   width: 80%;
}
.map{
   width: 100%;
   height: 450px!important; 
} 

.userMap{
    width: 100%;
   height: 80vw!important;   
   max-height: 450px!important; 
}
.css-1pahdxg-control:hover {

border:2px solid #E47100 !important;
}
.css-1pahdxg-control {
box-shadow: none !important;
border-radius: 8px !important;
border: 2px solid #E47100 !important;
}


.css-1pahdxg-control:hover {

    border:2px solid #E47100 !important;
    }
    .css-1pahdxg-control {
    box-shadow: none !important;
    border-radius: 8px !important;
    border: 2px solid #E47100 !important;
    }

 .invalid   .css-1pahdxg-control:hover {

        border:2px solid transparent !important;
        }
     .invalid   .css-1pahdxg-control {
        box-shadow: none !important;
        border-radius: 8px !important;
        border: 2px solid transparent !important;
        }



.css-1s2u09g-control {
border: none !important;
}
.css-b62m3t-container {


    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    border: none;
    padding: 0.1em 0;

}
.css-1hb7zxy-IndicatorsContainer {
    display: none !important;
}
@media screen and (max-width: 640px){
   
    .cstmr p {
        font-size: 20px;
        text-align: center;
    }
    .addressBody{
        padding: 2em 0.5em;
    } 
    
}

    @media screen and (max-width: 991px){
   
      
        .addressTitle{
           margin: 0;
        } 
        
    } 

    @media screen and (max-width: 500px){
   
    
        .addressTitle
    {
         font-size: 16px;
        } 
        .addressName{
            font-size: 14px; 
        }
        .location{
            font-size: 13px;  
        }
        .myAddress{
            padding: 0.5em;
        }
        .addressLoc{
            width: 220px;
        }
        .modal-p{
            top: 30%;
            font-size: 18px; 
        }
    }


    @media screen and (min-width: 1700px){/*
        .modal-p{
            font-size: 1.5vw !important;
  
        }
        .addressNav span{
            font-size: 1.6vw !important;
        }
        .addressNav{
            margin-bottom: 1em;
        }
        .addressInput input{
            font-size: 1.4vw !important;

        }
        .addressInput input::placeholder{
            font-size: 1.6vw !important;

        }
        .branchesPick select{
            font-size: 1.5vw !important;
height: unset !important;
        }
        .addressName{
            font-size: 1.6vw;  
            margin-bottom: 0.5em;
        }
        .location{
            font-size: 1.3vw;   
        }
        .addressLoc svg{
width: 1.5vw;
height: 1.5vw;
        }
        .chooseAddress button{
            font-size: 1.4vw;  
        }*/
    }