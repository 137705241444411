.barCode{
    background: #633235;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: auto;
    display: inline-block;

}
.spaceAround{
padding: 5%;
position: relative;
}
.barCode .cardTop{
    display: flex;
    justify-content: space-between;
margin-bottom: 5%;
}

.cardTop .date{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 0.7px;
    
    color: #FFFFFF;
    display: inline-block;
 margin-bottom: 0 ;
}
.dateT{
    display: inline-block;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: right;
    letter-spacing: -0.3px;
    
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0 ;
}


.spacebtn{
    display: flex;
    justify-content: space-between;  
}
.points{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 34px;
letter-spacing: 0.7px;

color: #FFFFFF;
margin-bottom: 0;
}

.pointsT{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 34px;
text-align: right;
letter-spacing: -0.3px;

color: #FFFFFF;
margin-bottom: 0;
}
.decore{
    position: absolute;
    bottom: 0;
    left: 0;
}