.bodyTitle p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
  
    letter-spacing: -0.3px;
    
    color: #E47100;
}
.bodyTitle div p{
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #3F3F3F;
}
.noData{
    text-align: center;
    height: 5em;
}
.noData p{
    position: relative;
    top: 50%;
    font-family: 'Cairo';
}
.corporateNav{
margin-bottom: 2em;
justify-content: space-between;
border-bottom: 1px solid #CDCDCD;
}
.bodyTitle div{

    background: #F4F4F4;
border-radius: 12px;
padding: 1em 1em;
margin-bottom: 2em;
}
.salesNavigation p{

    cursor: pointer;

font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 35px;

letter-spacing: -0.3px;
height: 65px;
color: #4F4F4F;
border-bottom: 2px solid transparent;
}
.salesNavigation p:hover{

  
    color: #e47100;
    border-bottom: 2px solid #E47100;
}
.activeSalesNav{
   
    color: #e47100 !important;
    border-bottom: 2px solid #E47100 !important; 
}


.salesNavigation{
    text-align: center;
    border-bottom: 1px solid #CDCDCD;
    margin: 3em 0;
    height: 65px;
}
.productItemBook{
    text-align: center;
    padding: 0.5em 1.2em !important;
 
  

    border-radius: 8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);

}
.menuBckg{
    height: 9em;
  
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position:  center;
    border-radius: 10px;
    margin: 1em 0;
margin-top: 0.7em;
}
.productName{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;

    letter-spacing: -0.3px;
    
    color: #4F4F4F;
flex-basis: 100%;
margin-bottom: 1em;


}
.productDescription{
  
font-family: 'Cairo';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;

letter-spacing: -0.3px;

color: #4F4F4F;
}
.medyafDetails p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.3px;
 
    /* Gray 3 */
    
    color: #828282;
   
 }
 .medyafMenu button{
    background: #E47100;
    border-radius: 10px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 34px;
letter-spacing: -0.3px;
color: #FFFFFF;
border: none;
width: 100%;
padding: 0.2em;

 }
 .paddingMenu{
     padding: 1em 0;
     padding-bottom: 0;
 }

 .medyafMenu button:hover{
    background: #f1861c;}
    .menuContainer{

        background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding:1em 1em;
    
    }

    .medyafMenu {
        text-align: center;
        background: white;
     border-radius: 12px;
     min-width: 236px;
     
     
     }    
     .medyafMenu .bckg{
        width: 100%;
        border-radius: 8px;
        height: 13vw;
        background-size: cover;
        background-repeat: no-repeat;
    background-position:  center;
    }
    .companyServices .bckg{
      
        width: 100%;
        border-radius: 8px;
        height: 10em;
        background-size: cover;
        background-repeat: no-repeat;
    background-position:  center;
    }
    .mmIcon{
        color: #DB095B;;  
        margin-right:  0.3em;  
    }
    
    
    .mmIconsAr{
        color: #DB095B;  
        margin-left:  0.3em;  
    }
    .mmIcon2{
        color: #DB095B;  
        margin-right:  0.5em;  
    }
    
    
    .mmIconsAr2{
        color: #DB095B;  
        margin-left:  0.5em;  
    }
     .medyafDetails{
        display: flex;
        justify-content: space-between;
    }
    .medyafDetails span{
        color: #331900;
        
    }
     .serviceTitle{

        font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 37px;

letter-spacing: -0.3px;

color: #633235;
    }
     .serviceDet{

        font-family: 'Cairo';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
   
        letter-spacing: -0.3px;
        
        color: #4F4F4F;
    }
    .callService{
        font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
background: #E47100;
border-radius: 8px;
border:none;
position: absolute;
bottom: 0;
left: 0;
width: unset;
padding: 0.2em 0.5em;
    }
    .callService:hover{
        background: #f88716;}

        .sales-customers div{
            width: 100%;
            max-width: 250px;
            height: 250px;
          background-size: contain;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            background-repeat: no-repeat;
            background-position: center;
           
            display: inline-block;
        }

        .sales-customers span{
margin: 0 1.5em;

        }

.cuHeader{
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;


letter-spacing: -0.3px;

color: #3F3F3F;


}


.cuTitle{
    margin-top: 2em;
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 45px;

letter-spacing: -0.3px;

color: #633235;
}
.cuTitles{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
 
    letter-spacing: -0.3px;
    
    color: #633235;
}

.contactForm{
    background: #F4F4F4;
border-radius: 12px;
padding: 1em 2em;
margin-bottom: 0 !important;
width: 90%;
}
.minPer{
    margin: auto !important;
    padding: 0 !important;
    font-family: 'Cairo';
}
.contactForm span{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgb(193, 22, 22);
    display: block;
}
.invalidSpan{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgb(193, 22, 22);
    display: block;

}
.contactForm label{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 41px;

    letter-spacing: -0.3px;
    display: block;
    color: #4F4F4F;
margin-bottom: 0.2em;
margin-top: 1em;
}
.contactForm input{
    background: #FFFFFF;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;
border: none;
color: #ACACAC;
padding: 0.5em;
width: 100%;
}
.contactForm textarea{
    background: #FFFFFF;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;
border: none;
color: #ACACAC;
padding: 0.5em;
width: 100%;
}
.contactForm button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    
    text-align: right;
    letter-spacing: -0.3px;
    color: white;
    background: #e47100;
    border-radius: 10px;

    padding: 0.5em 3em;
border: none;

}
.contactForm button:hover{
    background: #f08215;

}
.contactPhone p{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: normal !important;

letter-spacing: -0.3px;

color: #4F4F4F;
margin-bottom: 0;
}
.callServiceEn{

    right:0;
    left:unset}


.contactPhone div{
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 2.5em;
}
.contactPhone button{
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 100px;
  padding:0.7em;
    }
    .contactPhone button:hover{
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.15);
    }

    .cuSocials button{

        background: rgba(228, 113, 0, 0.1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 100px;
        width: 60px;
        height: 60px;
    margin-right: 2em;
    }
    .cuSocials button:hover{
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);

    }

    .cuSocialsEn button{
        margin-left: 2em ;
margin-right: 0;
    }
    .twitter img{
      position: relative;
      top:3px;
    }
   .cuSocials{
    margin-top: 3em; 
   }
   .callIcon{

    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
 }

 .messageSent{

    background: rgba(228, 113, 0, 0.1);
border-radius: 20px;
text-align: center;
padding-bottom: 5em ;
padding-top: 3em;
width: 60%;
display: inline-block;
 }
 .messageSent-p1{

    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 49px;
margin-bottom: 0;
letter-spacing: -0.3px;

color: #633235;
 }
 .messageSent-p2{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 45px;
   margin-bottom: 0;
    letter-spacing: -0.3px;
    
    color: #633235;
    
 }
 .msgImg{
     height: 15em;
     width: 15em;
     display: inline-block;
     background-position: center;
     background-size: cover;
 }
 .cuHeader{
margin-top: 0;
 }
.menuName{
 
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 20px;
padding: 0 !important;
letter-spacing: -0.3px;
margin-bottom: 0;
color: #633235;
}
.spRecomend{
    padding: 0 !important;
}
.menuDetail{
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
  
}
.menuMin{
  width: 100% !important;
    text-align: initial !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menuMin p{
    margin-bottom: 0;
}

.menuDetail p{

    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;

letter-spacing: -0.3px;

color: #4F4F4F;
}
.menuDetail span{
    font-size: 18px !important;  
}
.menuDetail div p{
display: inline-block;

}
.menuDetail div{
    width: 50%;
    text-align: end;
}
.menuDetail button{

    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
letter-spacing: -0.3px;
background: #E47100;
border-radius: 8px;
color: #FFFFFF;
border: none;
padding: 0.3em;
min-width: 194px;
width: 50%;
}
.menuDetail button:hover{


    background: #f38619;
}
.menuMeals{
margin:5em 0;

}
.mealImage{
    height: 100px;
    border-radius: 15px;
    width: 100%;
    background-size: cover;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: center;

 margin: 0.5em 0;
}
.menuMeals .col-md-2{
margin-top: 2em;
    text-align: center;
    background: white;
    border-radius: 12px;
    box-shadow: 0px 0px 12px rgb(0 0 0 / 12%);
    padding: 1em;


} 
.mealQuan{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.3px;
    color: #e47100;
}
.mealName{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.3px;
    color: rgb(99, 50, 53);
}
.bookMenu p{
  margin-bottom: 0;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  letter-spacing: -0.3px;
  text-align: start;
  margin-bottom: 0;

}
.bookMenu span{

    font-size: 20px !important;

}
.bookMenu{
    padding: 1em;
    border-bottom: 1px solid #CDCDCD;
}
.alignImg{
    display: flex;
    align-items: center;
}
.alignImg p{
    margin: 0 1em;
}
.bookImg{
    width: 200px;
    height: 120px;
    border-radius: 15px;

    background-size: cover;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: center;


}
.inquireForm{
    text-align: start;
}
.inquireForm label{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
   
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
    display: block;
margin-top: 1em;
margin-bottom: 0.5em;

}
.inquireForm input{

    background: #FFFFFF;
    border-radius: 8px;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    max-width: 550PX;
    letter-spacing: -0.3px;
    
    color: #ACACAC;
    border: 0.5px solid  #ACACAC;
    padding: 0.5em 10px;
    width: 100%;

}
.inquireBtn{
    max-width: none !important;
    margin-top: 0 !important;
}
.inquireSuucess p{
    color: rgb(99, 50, 53);
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: -0.3px;
  
    text-align: center;
position: relative;
top: 50%;
}
.inquireSuucess{
    height: 50vh;
}
.errMsg{
    display: block;
    color: brown;
}
.pricePerson{

    margin: inherit;
    
    justify-content: end;
}
.bookPrice{
    margin: 0 !important;
}

 @media screen and (max-width: 760px) {
    .corporateNav {
     overflow-x: auto;

    }
  
    .corporateNav p{
white-space: nowrap;

    }
    .callService{
font-size: 16px;
    }


  }

 
@media screen and (max-width: 575px) {
    .sales-customersAlign .sales-customers{
        display: flex !important;
        justify-content: center;
  
    }
    .cuHeader{

        font-size: 20px;
    }
    .cuTitles{
     
    
    }
    .menuDetail{
        flex-direction: column;
    }
.menuDetail div{
    text-align: start;
}
.menuDetail button{
   margin:auto !important;
}
  }


@media screen and (max-width: 991px) {
    .contactForm {
     
width: 100%;
    }
 
  }
  @media screen and (max-width: 603px) {
    .pricePerson{
        justify-content: start;
        margin-top: 1em !important;
    }
 
  }
  @media screen and (max-width: 991px){
  .medyafMenu .bckg{

    height: 25vw;
    max-height: 180px;

  }}

  @media screen and (max-width: 575px){
    .medyafMenu .bckg{

        height: 44vw;
        max-height: 200px;
      }}
  
  @media screen and (max-width: 500px){
 
    .menuDetail div{
        width: 100%;
    }
    .menuDetail button{
        margin: 0 !important;
    }
    .bodyTitle p{
        font-size: 14px;
        margin-bottom: 0.5em;
    }
    .bodyTitle div p{
        font-size: 13px;
        line-height:normal
    }
    .aboutus-nav p{
        font-size: 14px !important;
    }
    .aboutUs-title{
        font-size: 14px !important; 
    }
    .abstrac-title{
        font-size: 14px !important;   
    }
    .abstrac-p1{
        font-size: 13px !important;
    }
    .abstrac-p2{
        font-size: 13px !important;
    }
    .history-details{
        font-size: 13px !important;
    }
    .imgCaption p{
        font-size: 13px !important;
    }
    .corporateNav p{
        font-size: 14px !important;
    }
    .menuName{
        font-size: 14px ;
    }
    .menuDetail p{
        font-size: 13px;
    }
    .menuDetail div p span{
        font-size: 14px !important; 
    }
    .menuDetail button{
        font-size: 13px;
    }
    .mealQuan{
        font-size: 13px; 
    }
    .mealName{
        font-size: 13px;  
    }
    .spRecomend p{
        font-size: 14px
    }
    .recommend-name{
        font-size: 14px !important
    }
    .recommend-price{
        font-size: 13px !important
    }
    .medyafMenu button{
        font-size: 14px !important  
    }
    .bookMenu p{
        font-size: 14px !important
    }
    .bookMenu p span{
        font-size: 14px !important
    }
    .inquireForm label{
        font-size: 14px  
    }
    .giftContainer button{
        font-size: 13px;
    }
    .serviceTitle{
        font-size: 14px;
        margin-bottom: 0;   
    }
    .serviceDet{
        font-size: 13px;
    }
    .callService{
       left: 10px;
       font-size: 14px;
       bottom: -20px;
       padding: 0 0.5em
    }
    .companyServices .bckg{
        height: 6em;
    }
    .callServiceEn{
        font-size: 14px;
        right:10px;

        left: unset !important;
       }
     input::placeholder{
font-size: 13px;

       }
       .cuHeader{
           font-size: 14px;
           line-height: normal;
       }
       .contactSuccess{
           width: 335px !important;
       }
       .cuTitles{
           font-size: 14px;
       }
       .contactForm label{
        font-size: 14px;
       }
       .contactForm input{
        font-size: 13px;
       }
       .contactForm button{
        font-size: 13px;  
       }
       .contactPhone p{
        font-size: 14px;  
       }
       .cuSocials button{
           height: 50px;
           width: 50px;
       }
       .backBtn button{
        font-size: 14px !important;  
       }
       .newsTitle{
        font-size: 14px !important;   
       }
       .newsDate{
        font-size: 13px !important;
       }
       .newsDescr p{
        font-size: 13px !important;  
       }
       .scrollNewsTitle{
        font-size: 14px !important;    
       }
       .scrollTitle p{
        font-size: 13px; 
       }
       .aboutusImg{
           min-height: 150px !important;
       }
       .medyafMenu p{
        font-size: 14px ;    

       }
       .medyafDetails p{
        font-size: 13px; 

       }
       .noData p{

        font-size: 13px; 
       }
       .noData{
height: 2em;
       }
}     

@media screen and (min-width: 1700px){
    
    
    .medyafMenu .bckg{
        height: 13vw;
    }
    /*
    .bookImg{
        width: 33vw;
        height: 10vw;
    
    }
    .inquireForm label{
        font-size: 1.5vw !important; 
    }
    .inquireForm input{
    padding:1em ;
    font-size: 1.3vw !important; 
    }
    .giftContainer button{
        font-size: 1.4vw ;   
        max-width: 400px !important;
    }
    .bookMenu p{
        font-size: 1.5vw;
    }
.medyafMenu p{
    font-size: 1.4vw;

}
.medyafDetails p{
    font-size: 1.2vw;

}
.medyafDetails svg{
height: 1.5vw;
width: 1.5vw;
}
.serviceTitle{
    font-size: 1.6vw;

}
.serviceDet{
    font-size: 1.4vw;

}
.callService{
    font-size: 1.4vw;

}
.paddingMenu{
    padding: 2em 0;
}
.companyServices .bckg{
    height: 12vw;
}
.medyafMenu .bckg{
    height: 13vw;
}
.sales-customers div{
    max-width:22vw;
    height: 22vw;
}

.medyafMenu button{
    font-size: 1.4vw;
    padding:  0.5em 0;

}
.menuName{
    font-size: 1.7vw;

}
.menuDetail p{
    font-size: 1.6vw;

}
.menuDetail span{
    font-size: 1.8vw !important;

}
.menuDetail button{
    font-size: 1.8vw;

}
.mealQuan{
    font-size: 1.6vw;
 
}

.mealName{
    font-size: 1.6vw;
 
}
.mealImage{
    height: 8vw;
}
.spRecomend p{
    font-size: 1.7vw;
  
}
.recommmendEn .swiper-button-next{
width: 4vw;
height: 4vw;
}
.recommmendEn .swiper-button-prev{
    width: 4vw;
    height: 4vw;
}

.recommmendAr .swiper-button-next{
    width: 4vw;
    height: 4vw;
    }
    .recommmendAr .swiper-button-prev{
        width: 4vw;
        height: 4vw;
    }
    .cuHeader{
        font-size: 1.6vw;
    }
    .cuTitles{
        font-size: 1.6vw;
margin-bottom: 0.5em !important;
    }
    .contactForm label{
        font-size: 1.4vw;
        margin-bottom: 0.5em;

    }
    .contactForm input{
        font-size: 1.2vw;
        padding: 1em;
    }
    .contactForm input::placeholder{
        font-size: 1.2vw;
    }
    .contactForm button{
        font-size: 1.4vw;

    }
    .contactPhone p{
        font-size: 1.4vw;  
    }
    .cuSocials .cuTitles{
margin-bottom: 1em !important;
    }
    .contactPhone button{
       
    }
    .callIcon{
        height: 2.5vw;
    }
    .cuSocials img{
        height: 3vw;
        width: 3vw; 
    }
    .cuSocials button{
        height: 4vw;
        width: 4vw; 
    }*/
}