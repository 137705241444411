@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700&family=Lato:wght@700&family=Montserrat:wght@500&display=swap');

.App{
    height: 100%;
}
a{
    width: unset;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
    textarea,
    input {
      font-size: 16px !important;
    }
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    z-index: 1;
}