.awafiHeader{
    text-align: center;
    margin-top: 2em;
}
.awafiHeader p{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 45px;

letter-spacing: -0.3px;

color: #E47100;
}
.awafiImg{
    height: 24em;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
 display: inline-block
}
.awafiTerms p{

    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 35px;

letter-spacing: -0.3px;

color: #633235;
}

.awafiTerms li{
    display: inline;
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;

letter-spacing: -0.3px;
direction: initial;
color: #4F4F4F;
text-align: revert;
}

.awafiTerms ul {
    list-style-type: none;
   }
   .awafiTerms .bullet{


    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 45px;
letter-spacing: -0.3px;

color: #E47100;

background: rgba(228, 113, 0, 0.15);
display: inline-block;
border-radius: 100%;
margin-left: 1em;
height: 40px;
width: 40px;
text-align: center;
padding: 0 0.5em;

display: flex;
justify-content: center;


   }
   .awafiTermsEn .bullet{
    margin-left: 0 ;
    margin-right: 1em ;

   }
   .bullet p{
    color: #E47100;

   }
   .liItem{
       margin-bottom: 0.5em;
       display: flex;
   }
   .li-nested .bullet{

    background-color: transparent;
   
   }
   .li-nested .bullet p{

    color: transparent ;
   }

   .li-nested span{
       
font-family: 'Cairo';
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 112%;

letter-spacing: -0.3px;

color: #DB095B;
   }
   .li-nested li{

    font-family: 'Cairo';
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 112%;

letter-spacing: -0.3px;

color: #4F4F4F;

   }
   .li-nested{
    margin-bottom: 0.4em;
   }
   .liAlert{

font-family: 'Cairo';
font-style: normal;
font-weight: 400 !important;
font-size: 18px !important;
line-height: 27px !important;

letter-spacing: -0.3px;

color: #633235;
   }

   .awafiForm p{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;

letter-spacing: -0.3px;
display: block;
color: #420F12;
   }
   .awafi-fromBody{
    background: #F4F4F4;
    border-radius: 12px;
text-align: center;
padding: 3em 0;
   }


   .awafiForm label{
display: block;
margin-top: 0.6em;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 41px;

letter-spacing: -0.3px;

color: #4F4F4F;

   }
   .awafiForm input{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    background: #FFFFFF;
    border-radius: 8px;
    letter-spacing: -0.3px;
    border: none;
    color: #ACACAC;
 width: 100%;
    padding: 0.5em;
       }
       .inputDiv{
        width: 40%;
        display: inline-block;
      text-align: initial;
       }
       .inputDiv span{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: rgb(193, 22, 22);
        display: block;
margin: 0.2em 0;
       }
       .invalid{
border: 2px solid rgb(193, 22, 22) !important;

       }
       .calendarIcon{
           position: absolute;
           right: 20px;
           top: 10px;
           color: #ACACAC;
       }

       .inputDiv select{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        background: #FFFFFF;
        border-radius: 8px;
        letter-spacing: -0.3px;
        border: none;
        color: #ACACAC;
     width: 100%;
        padding: 0.5em;

       }
       .gender input{
           width: unset;
       cursor: pointer;
       }
       .gender label{
      display: inline-block;
      margin: 0 0.5em;
    } 
    .awafiTerms label{

        font-family: 'Cairo';
font-style: normal;
font-weight: 600 !important;
font-size: 18px !important;
line-height: 27px;

letter-spacing: -0.3px;

color: #4F4F4F;
    }
    .awafiTerms{
        margin-top: 1em;
    }
    .awafiForm button{
        background: #E47100;
border-radius: 8px;
border: none;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 37px;

letter-spacing: -0.3px;
margin-top: 3em;
color: #FFFFFF;
width: 40%;
    }
    .awafiForm button:hover{

        background: #f18111;
    }
    .membership-title{

font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 18px;

letter-spacing: -0.3px;

color: #633235;
margin-bottom: 0.2em;
    }
    .awafiForm .otpEn div {
        justify-content: start !important;
    }
    .membership-text{

        font-family: 'Cairo';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 215%;
 
        letter-spacing: -0.3px;
        margin-bottom: 0.5em;
        color: #4F4F4F;
            }
            .membership-list p{

font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 215%;

letter-spacing: -0.3px;

color: #4F4F4F;
            }
            .membership-list span{


color: #E47100; 
            }
            .awafiMembership{
             
            }
            .pointsExchange{
                background: #F4F4F4;
border-radius: 12px;
height: 12em;
margin-bottom: 1em;
            }

            .exchangeTitle{
                font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;


letter-spacing: -0.3px;

color: #633235;
            }
            .calc{
                flex-direction: column;
                justify-content: center;
                display: flex;
            }
            .calc-title{
                font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;


letter-spacing: -0.3px;

color: #4F4F4F;
            }
            .calc-body label{
            font-family: 'Cairo';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
          
          
            letter-spacing: -0.3px;
            
            color: #4F4F4F;

            }  
              .calc-body input{


                color: #ACACAC;
                font-family: 'Cairo';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
            
                letter-spacing: -0.3px;
                background: #FFFFFF;
border-radius: 4px;
border: none;
margin: 0 1em;
padding: 0.4em 0.3em;
              }
              .awafiForm input::placeholder{
font-size: 16px;
              }
              .calc-body .equal{
                font-family: 'Cairo';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
           
                letter-spacing: -0.3px;
                
                color: #DB095B;
                
              }
              .calc-body .pointsCurrency{
 
                font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;

letter-spacing: -0.3px;
margin-bottom: 0;
color: #E47100;

              }
              .pointsAlign{
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }
              .calc-body{
                  display: flex;
                  align-items: center;
              
              }
              .question-title{
                font-family: 'Cairo';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
              
                letter-spacing: -0.3px;
                
                color: #633235;
                margin: 1em 0;
              }
              .question{
                font-family: 'Cairo';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
          
                letter-spacing: -0.3px;
                
                color: #DB095B;
                
              }
              .question-answer{
                font-family: 'Cairo';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
          
                letter-spacing: -0.3px;
                
                color: #4F4F4F;
              }

              .calcLbl{
                font-family: 'Cairo';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                letter-spacing: -0.3px;
                color: #4F4F4F;
                display: none;

              }
              @media screen and (max-width: 682px) {
                .calc-body label {
                 display: none;
                }
                .calcLbl{
                    display: block;
                }
          
            .calcNumber{
                margin-right: 0 !important;
            }
            .calcNumberEn{
                margin-left: 0 !important;
                margin-right: 1em !important;
            }
            .exchangeTitle{
            
                font-size: 20px;
            }

            .calc-body input{
                width: 30%;
            }

              }
              @media screen and (max-width: 800px) {
                .inputDiv{
              width: 80%;
                }
            
            
                .awafiTerms label {
                    font-size: 18px !important;
                }
                .awafiForm p {
               
                }
                .calc-body input{
width: 108px;
                }
            }

            @media screen and (max-width: 500px) {
                .awafiHeader p{
                    font-size: 18px;
                }
                .awafiImg{
                    height: 13em;
                }
                .awafiForm p{
                    font-size: 14px;
                }
                .awafiForm label{
                    font-size: 14px; 
                }
                .awafiForm input{
                    font-size: 13px; 
                }
                .awafiForm button{
                    font-size: 13px; 
                }
                .membership-title{
                    font-size: 14px;  
                }
                .membership-text{
                    font-size: 13px; 
                    line-height:normal; 
                }
                .membership-list p{
                    font-size: 13px; 
                }
                .exchangeTitle{
                    font-size: 16px; 
                    margin-bottom: 0;
                }
                .calc-title{
                    font-size: 14px;
                }
                .calcLbl{
                    font-size: 14px; 
                }
                .calc-body input{
                    font-size: 14px; 
                }
                .calc-body .pointsCurrency{
                    font-size: 14px; 
                }
                .awafiTerms p{
                    line-height: normal;
                    font-size: 14px; 
                }
                .awafiTerms li{
                    font-size: 13px;  
                }
                .bullet p{
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                }
                .awafiTerms .bullet{

                    width: 30px;
                    height: 30px;
                }
                .question-title{
                    font-size: 16px;  
                    margin: 1.5em 0; 
                }
                .question{
                    font-size: 14px;  
               
                }
                .question-answer{
                    font-size: 13px;   
                }
                .awafiTerms label{
                    font-size: 14px !important;     
                }
            }


            @media screen and (min-width: 1700px){/*
                .awafiHeader p{
                    font-size: 1.8vw;
                    margin-bottom: 1em;
                }
                .awafiImg{
height: 33vw;
                }

                .membership-title{
                    font-size: 1.6vw;
                }
                .membership-text{
                    font-size: 1.4vw;

                }
                .awafiForm p{
                    font-size: 1.8vw;
  
                }
                .awafiForm label{
                    font-size: 1.6vw; 
                    margin-bottom: 0.5em;
                }
                .awafiForm input{
                    font-size: 1.2vw !important;

                }
                .awafiForm input::placeholder{
                    font-size: 1.2vw !important;

                }
                .awafiTerms label{
                    font-size: 1.4vw !important;  
                }
                .awafiForm button{
                    font-size: 1.4vw !important;  
padding: 0.5em;
                }
                .otpForm input{
                    width: 50px;
                    height: 60px;
                }
                .membership-list p{
                    font-size: 1.3vw;    
                }
                .calc-title{
                    font-size: 1.6vw;    

                }
                .calc-body label{
                    font-size: 1.4vw;    

                }
                .calc-body input{
                    font-size: 1.2vw !important;
                    
                }
                .calc-body input::placeholder{
                    font-size: 1.2vw !important;
                }
                .calc-body .pointsCurrency{
                    font-size: 1.8vw;    
                }
                .calc-body .equal{
                    font-size: 2vw;    
   
                }
                .awafiTerms p{
                    font-size: 1.6vw;   
                }
                .awafiTerms li{
                    font-size: 1.3vw;   

                }
                .question-title{
                    font-size: 1.6vw;   

                }
                .question{
                    font-size: 1.4vw;   

                }
                .question-answer{
                    font-size: 1.3vw;   

                }*/
            }