#pass1{
    padding: 0.6em 1em;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

letter-spacing: 3px;
color: #ACACAC;
}
#confirmPassword{
    padding: 0.6em 1em;  
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */
letter-spacing: 3px;

color: #ACACAC;
}
.passPhoneImage img{
    position: absolute;
    left: -1.5em;
}
.passPhoneImageEn img {
    position: absolute;
    right: -1.5em;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.confirmChange {
    background: #E47100;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
border: none;
display: block;
padding: 0.3em 0;
width: 100%;
margin-top: 2em;
}
.confirmChange:hover{
    background: #f58311;

}

.confirmAlign{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.otpPhone{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #633235;
}
.otpImage{
    background: rgba(228, 113, 0, 0.1);
border: 10px solid #F2E7DC;
border-radius: 28px;
padding: 0.4em;
}

.otpForm label{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 41px;

letter-spacing: -0.3px;

color: #4F4F4F;
margin-bottom: 0.5em;
}
.otpForm input{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    
    letter-spacing: -0.3px;
    
    color: #ACACAC;
background: #FFFFFF;
border-radius: 8px;
border: none;
width: 40px !important;
height: 44px;
margin: 0 0.5em;
text-align: center !important;
}

.invalidOtp{

    color: #c11616;
    display: block;
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
}

.otpForm button{
    background: rgba(228, 113, 0, 0.5);
border-radius: 8px;
width: 100%;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
border: none;
margin-top: 2em;
margin-bottom: 0.7em;
}


.otpForm button:hover{

    background: rgba(228, 114, 0, 0.662);
 
}

.resendChangePass{
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 37px;

letter-spacing: -0.3px;

color: #4F4F4F;
}
.resendChangePass .resendSpan{

    text-decoration-line: underline;

color: #E47100;
cursor: pointer;
}



@media screen and (max-width: 767px){
   
    .passPhoneImage img{
      display: none;
    }
    .otpForm input {

        width: 26px !important;
        margin: 0 0.25em
    }
    .resendChangePass{
        line-height: 20px;
        font-size: 18px;
    }
    .login{
padding: 1em !important;
    }
      
    .passAlignImg img{
        display: none;
      }
      .otpForm button {

        max-width: 400px; 
      }
      .passForm input {

        max-width: 500px;
      }
      .passForm button {
        max-width: 400px;
        display: inline;
      }
      .passForm span {
          text-align: center !important;
      }
      .passForm label {
          text-align: center;
      }
} 