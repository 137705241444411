.branchesBanner{
    height: 30vw;
    width: 100%;
    background-size: cover;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-top: 2em;
}
.branchImage{
    background-size: cover;
    border-radius: 4px;
    background-repeat: no-repeat;
    height: 13vw;
   margin-bottom: 1em ;
    width: 100%;
    background-position: 50%;
    min-height: 10em;
}
.brnchHeader{
    display: flex;
   
    align-items: center;
}
 

.branchesTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 24px;

letter-spacing: -0.3px;

color: #E47100;

}

.srtTitle{ font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
  
    letter-spacing: -0.3px;
    display: inline-block;
    margin: 0 1em;
    color: #4F4F4F;}

.sortOptions p{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 37px;
  
    letter-spacing: -0.3px;
    display: inline-block;
    margin: 0 1em;
    color: #4F4F4F;
}
.sortOptions label{
font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 34px;
/* identical to box height */

text-align: right;
letter-spacing: -0.3px;

color: #4F4F4F;
margin: 0.5em;}
.sortOptions div{
    display: inline-block;
}
.spaceBtn{
margin-right: 2.5em;

}

.branch{
    background: #FFFFFF;
box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
border-radius: 8px;
padding: 1em;
text-align: center;
}
.sortOptions select{
    background: #F4F4F4;
    border-radius: 12px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 34px;

letter-spacing: -0.3px;

color: #4F4F4F;
border: none;
padding: 0.2em ;
width: 8em;
cursor: pointer;
}
.sortOptions select:focus{
border: 2px solid #E47100;

}
.brnchName{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
  
    letter-spacing: -0.3px;
    
    color: #633235;
    margin-bottom: 0;
}
.brnchLocation{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
    height: 3.3em;
   
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.openHours{

}
.brnchTime{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #4F4F4F;
display: inline-block;
margin: 0 1em;
}
.brnchStateClosed{
    background: rgba(170, 39, 39, 0.15);

color: #aa2727;
}
.brnchState{

    background: rgba(39, 170, 76, 0.15);
    border-radius: 20px;
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 30px;

letter-spacing: -0.3px;

color: #27AA4C;
display: inline-block;
padding: 0.2em 0.5em;
margin: 0 1em;
}
.brnchBtn1{
    border: 1px solid #E47100;
    background-color: white;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 37px;
letter-spacing: -0.3px;

color: #E47100;
width: 40%;
margin:0 0;
box-sizing: unset;
}
.brnchBtn2{
    
    border: 1px solid transparent;
    background-color: #E47100;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 37px;
letter-spacing: -0.3px;
width: 40%;
color: white; 

margin:1em 0;
margin-top:0;
box-sizing: unset;
}
.brnchBtns{
    margin-top: 2em;
}

.brnchBtns .spacing {
 margin:2%;
}
@media screen and (max-width: 800px) {



    .brnchHeader {
    

    }
    .brnchHeader p{
   
     }
    .srtTitle{
margin-right: 0 !important;
    }
    .srtTitleEn{
        margin-right: 1em !important;
        margin-left: 0 !important;
            }
  
}

@media screen and (max-width: 1307px) {

  
 
    }
    @media screen and (max-width: 991px) {

     
       
     
        }
@media screen and (max-width: 927px) {



}
@media screen and (max-width: 575px) {

   
    .brnchName{

    }
    .branchImage{
        height: 38vw;

    }
    }
    @media screen and (max-width: 415px) {

       
       
     
        }
       
        @media screen and (max-width: 500px) {

            .branchesTitle{
            font-size: 14px;
            }
           
            .sortOptions p{
                font-size: 13px;
                }
                .sortOptions select{
                    font-size: 13px;
                    }
                    .brnchName{
                        font-size: 14px;
                        }
                        .brnchLocation{
                            font-size: 13px;  
                            margin-bottom: 0; 
                        }
                        .brnchTime{
                            font-size: 13px;     
                        }

                        .brnchState{
                            font-size: 13px;  
                        }
                        .brnchBtn2{
                            font-size: 14px;  
                        }
                        .brnchBtns{
                            margin-top: 1em;
                        }
                        
            }

            
            @media screen and (min-width: 1700px){/*

                .branchesTitle{
                    font-size: 1.4vw;
                    }
                   
                    .sortOptions p{
                        font-size: 1.3vw;
                        }
                        .sortOptions select{
                            font-size: 1.3vw;
                            }
                            .brnchName{
                                font-size: 1.4vw;
                                }
                                .brnchLocation{
                                    font-size: 1.3vw;
                                    margin-bottom: 0; 
                                }
                                .brnchTime{
                                    font-size: 1.3vw;    
                                }
        
                                .brnchState{
                                    font-size: 1.3vw;   
                                }
                                .brnchBtn2{
                                    font-size: 1.4vw;  
                                }
                                .brnchBtns{
                                    margin-top: 1em;
                                    font-size: 1.4vw;  
                                }
                                .srtTitle{
                                    font-size: 1.3vw;    
                                }
                                .brnchBtns svg{
height: 2vw;
width: 2vw;

                                }
                                .brnchBtns button{
padding: 0.5em 0;

                                }
                                .brnchBtns .brnchBtn1 svg{
                                    height: 2.5vw;
                                    width: 2.5vw;
                                }*/
            }