 .branchesPick{
     display: flex;
     justify-content: space-between;
 }
 .branchesPick select{

    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;

letter-spacing: -0.3px;

color: #4F4F4F;
border: none;
background: #F4F4F4;
border-radius: 12px;
width: 10em;
padding: 0 0.5em;
cursor: pointer;
height: 45px;
 }
 .branchMap{
     width: 100%;
     height: 65vw;
  
min-height: 620px;
 }
 .gmnoprint{
     bottom: 60% !important;
 }
 .branchesSlider{


    width: 100%;
    background: rgba(0, 0, 0, 0.37);
backdrop-filter: blur(6px);
position: absolute;
bottom: 0;
/*
height: 34vh;*/
 } 
 .chooseCity .noCity{
    background: rgba(228, 113, 0, 0.1);
    border-radius: 24px;
    text-align: center;
    position: relative;
    top: 5%;
    padding: 5em 1em;
 display: flex;
 align-items: center;
 justify-content: center;
 } 

 .chooseCity{
     height: 75vh;
 }
.noNear{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
line-height: 27px;
    letter-spacing: -0.3px;
    
    color: #633235;
} 
.choose{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;

    letter-spacing: -0.3px;
    
    color: #4F4F4F;
    
}
.branchesCrsl  {
    padding: 0 4em !important;
}

 .branches{

    background: #FFFFFF;
border-radius: 12px;


 }
 .branches button{
    background-color: #F4D9BF;
 }
 .branchImg{
     background-position: center;
     background-repeat: no-repeat;
     border-radius: 12px;
     width: 120px;
height: 120px;
display: inline-block;
background-size: cover;

 }
 .branchName{

font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;

letter-spacing: -0.3px;

color: #E47100;
margin-bottom: 0.5em;
 }
 .branchState{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
 padding: 0.2em 0.5em;
    letter-spacing: -0.3px;
    text-align: center;
    color: #27AA4C;
    display: inline-block;
background: rgba(39, 170, 76, 0.15);
border-radius: 20px;
margin-bottom: 0.5em;
 }

.branchStateClosed{

    color: #b21717;
  
background: rgba(166, 28, 28, 0.15);
}

 .branchTime{
     
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 14px;
display: inline-block;
letter-spacing: -0.3px;
display: block;
color: #4F4F4F;
margin-bottom: 0;

 }
 .branches .arrow-button2{
    border-radius: 0 0 12px 12px;
 }
 .branchPosition{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    text-align: center;
    letter-spacing: -0.3px;
    margin-top: 1em;
    color: #4F4F4F;
margin-bottom: 0;
    height: 3.3em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
 }
 .branches button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;

    letter-spacing: -0.3px;
    
    color: #E47100;
 


border: none;
padding: 0.2em 0;
 }
 .branches button:hover{

   
}
.branchPosition span{
   color: #E47100;
    
}
.branchDetails{
    display: inline-block;
    margin:0  1em;
 
}
.verticalAlign{
    position: relative;
 /*   top: 53%;
    transform: translateY(-50%);*/
    transform: translateY(3%);
}



.noBranch {
text-align: center;
}

.noBranch div{
    height: 128px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 1em;

}
.noBranch p {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;

    letter-spacing: -0.3px;
    
    color: #FFFFFF;
    
    } 
    .mapMargin{
        position: relative;
        margin-bottom:  2em;
    }
    
    @media screen and (max-width: 486px){
   
        .branchesCrsl{
        padding: 0 1em !important;
        }

 .branchMap{
     min-height: 700px;
 }
        
    }


.btnAr .swiper-button-prev {
    background-image: url("../Images/arrow-right.svg") !important;
    background-repeat: no-repeat;
   

    background-color: rgba(255, 255, 255, 0.18);
box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
backdrop-filter: blur(61px);

    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
  }
  .btnAr  .swiper-button-prev:hover {
 
    background-color: rgba(255, 255, 255, 0.5);
   
  }
  .btnAr   .swiper-button-prev:after, .myCarousel  .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
  



.btnAr .swiper-button-next {
    background-image: url("../Images/arrow-left.svg") !important;
 
    background-repeat: no-repeat;
 
    background-color: rgba(255, 255, 255, 0.18);
box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
backdrop-filter: blur(61px);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
    
  }
  .btnAr .swiper-button-next:hover {
 
    background-color: rgba(255, 255, 255, 0.5);
   
  }
  .btnAr .swiper-button-next:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
.btnEn .swiper-button-prev {
    background-image: url("../Images/arrow-left.svg") !important;
    background-repeat: no-repeat;
  
    background-color: rgba(255, 255, 255, 0.18);
box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
backdrop-filter: blur(61px);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
    
  }
  .btnEn  .swiper-button-prev:hover {
 
    background-color: rgba(255, 255, 255, 0.5);
   
  }
  .btnEn   .swiper-button-prev:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
  
.btnEn .swiper-button-next {
    background-image: url("../Images/arrow-right.svg") !important;
 
    background-repeat: no-repeat;
  
    background-color: rgba(255, 255, 255, 0.18);
box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
backdrop-filter: blur(61px);
    background-position: center;
    border-radius: 50%;
    padding: 1em 1.3em;
    
  }
  .btnEn .swiper-button-next:hover {
 
    background-color: rgba(255, 255, 255, 0.5);
   
  }
  .btnEn .swiper-button-next:after, .myCarousel .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}
.chooseCity .noCity{
height: 400px;
}
@media screen and (min-width: 1265px){
    .branchMap{
    max-height:700px !important;
    }}
    @media screen and (max-width: 1189px){
        .branchMap{
            min-height: 715px;
        max-height: unset !important;
        }}

@media screen and (max-width: 500px){
   .chooseCity{
    height:530px !important;
   }
    .addressNav p{
        font-size:14px 
    }
    .addressNav p span{
        font-size:14px  !important
    }
    .branchesPick select{
        width: auto;
        font-size:14px ;
        height: fit-content;
        padding: 0.5em;
    }
   .branchesPick{
margin-top: 1em;
    }
    .mapMargin{
        margin-top: 2em;
    }
    .branchName{
        font-size:14px 
    }
    .branches button{
        font-size:13px  
    }
    .branchState{
        font-size:13px  ;
    }
    .branchTime{
        font-size:13px  ;
     
    }
    .branchPosition{
        font-size:13px  ;

        height: 4.3em;
    }
   }

   @media screen and (min-width: 1700px){/*

    .branchName{
        font-size: 1.5vw;   
    }
    .branchState{
        font-size: 1.2vw;   

    }
    .branchTime{
        font-size: 1vw;   

    }
    .branchPosition{
        font-size: 1vw;   

    }
    .branchPosition{

        font-size: 1vw;   

    }
    .branches .arrow-button2{
        font-size: 1.4vw;   

    }
    .branchesSlider{
        max-height:19vw
      }
      .branchMap{
        max-height:40vw !important;
 
      }
      .mmsideNav-title{
        font-size: 1.7vw;   
 
      }
      .mmsideNav-link p{
        font-size: 1.4vw;   

      }
      .mmsideNav-link{
        border-width: 8px !important;
  
      }
      .mmsideNav-linkActive{
          border-width: 8px;
      }
      .mmsideNav-link img{
          height: 3vw;
          width: 3vw;
          bottom: 0;
      }
      .mmsideNav-link{
        overflow: auto;
        display: flex;
        align-items: center;
      }
      .mmItem-title{
        font-size: 1.7vw;
      }
      .mmFlex input{
        font-size: 1.5vw !important; 
        padding:20px 2.5em
      }
      .mmFlex input::placeholder{
        font-size: 1.5vw !important; 
      }
      .mmIcon{
        height: 2vw;
        width: 2vw;  
        top: 1vw ;
      }
      .mmMenuItems .mmName{
        font-size: 1.4vw ; 

      }
      .mmMenuItems .mmDescription{
        font-size: 1.3vw ; 

      }
      .spData p{
        font-size: 1.6vw ; 
 
      }
      .spData label{
        font-size: 1.6vw ; 
 
      }
      .spData .col-6{
          display: flex;
          align-items: center;
      }
      .btnTitle{
        font-size: 1.6vw ;   
      }
      .spQuantity{
        font-size: 1.6vw ;   
 
      }
      .spAdd{
        font-size: 1.4vw ;     
      }
      .sp-p1{
        font-size: 1.7vw ;   
      }
      .sp-p2{
        font-size: 1.8vw ;      
      }
      .sp-p3{
        font-size: 1.3vw ;        
      }
      .spImage .pImage{
          height: 30vw;
      }
      .spData input{
          width: 1.5vw;
          height: 1.5vw;
      }
      .spCategories .mmSideNav .mmsideNav-link{
       overflow: hidden;
      }
      .mmMenuItems .mmDescription{
          height: 57px;
      }
      .mmMenuItems .mmPrice{
        font-size: 1.5vw ; 

      }
      .mmMenuItems button{
        font-size: 1.4vw ; 
padding: 0.5em 0;
height: unset;
      }*/
}