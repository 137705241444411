.aboutus {
    display: flex;
}
.aboutus-nav p{
    font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 27px;

letter-spacing: -0.3px;
cursor: pointer;
color: #4F4F4F;
padding: 0.5em 0.5em;
}
.aboutusBody{
    flex: 3 1;
 
    margin-bottom: 100px;
}
.aboutus-nav p:hover{

    background: #FBEAD9;
    border-radius: 8px;

color: #E47100;

}
.aboutus-active{

    background: #FBEAD9;
    border-radius: 8px;

color: #E47100 !important;

}
.aboutus-nav {


border: 1px solid #CDCDCD;
border-style: none none solid solid;
padding: 1em ;
padding-right: 0;

}
.aboutus-body {

flex: 5;
    padding-right:  2em;
}
.aboutus-bodyEn{
    padding: 0;
    padding-left:  2em;
}
.aboutus-navEn{

    border-style: none solid solid none ;
    padding-right: 1em ;
    padding-left: 0;
}
 
.aboutUs-title{

    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 49px;

letter-spacing: -0.3px;

color: #633235;
}
.history-details{
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 27px;

letter-spacing: -0.3px;

color: #4F4F4F;

}
.abstrac{
    background: #F4F4F4;
border-radius: 12px;
padding: 1em;
}
.abstrac-title{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
 
    letter-spacing: -0.3px;
    
    color: #633235;
    
}
.abstrac-p1{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  
line-height: 28px;
    letter-spacing: -0.3px;
    
    color: #4F4F4F;
}
.abstrac-p2{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;

letter-spacing: -0.3px;

color: #DB095B;
}
.aboutusImg{

    height: 20vw;
    min-height: 200px;
   width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    cursor: pointer;
    background-repeat: no-repeat;
}
.imgCaption{ 
width: 100%;
background: rgba(0, 0, 0, 0.6);
padding: 1em;
position: absolute;
bottom: 0;
}
.imgCaption p{

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
  
    margin-bottom: 0;
    color: #FFFFFF;

    }
    .mag-title{
        font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 37px;
letter-spacing: -0.3px;
margin-bottom: 0;
color: #633235;
    }

    .mag-details{
        font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 37px;
letter-spacing: -0.3px;

color: #4F4F4F;

    }
    .mag-details{
        font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;
letter-spacing: -0.3px;

color: #4F4F4F;

    }
 .magazineImg{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
height: 10vw;
background-position: center;

background: #E47100;
border-radius: 6px;
background-repeat: no-repeat;
background-position-y: center; 


background-position-x: center; 
}
.mag-Body{
    border-bottom:  1px solid #CDCDCD;;
}

.backBtn{
    text-align: end;
}

.backBtn button{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    border: 1px solid #E47100;
    border-radius: 8px;
    letter-spacing: -0.3px;
    background-color: white;
    color: #E47100;
    padding: 0.2em 1em;
 
    min-width: 200px;
    white-space: nowrap;
}
.backBtn button:hover{

    background-color: #E47100;
    color: white;
  

}

.newsImg{
    height: 40vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;

}
.newsTitle{
    text-align: start;
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 20px;
color: #4F4F4F;
margin-bottom: 1em;
} 
.newsData{
    margin-top: 2em !important;
    margin-bottom: 10em;
}
.newsDate{

    font-family: 'Cairo'; 
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 26px;
color: #E47100;
margin-top: 1em;
text-align: start;
}

.newsDescr{
    font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 26px;
color: #4F4F4F;
text-align: start;
}
.scrollTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 20px;
margin-top: 1em ;
color: #4F4F4F;
}
.scrollNewsTitle{
    font-family: 'Cairo';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 26px;
margin-bottom: 2em;
color: #DB095B;

}
.scrollNews .col{
    border-bottom: 1px solid #CDCDCD;
    margin-bottom: 2em;
}
.scrollNews{
    border-right: 1px solid #CDCDCD;
 position: relative;
 top: 3em;
 

}

.scroller{

    height: 40em;
    overflow: auto;
}

.scrollNewsEn{

    border-left: 1px solid #CDCDCD;
    border-right: none;

}

@media screen and (min-width: 768px){
    .aboutus-nav{
        min-width: 220px;
    }

}
@media screen and (max-width: 767px){
   
      
    .aboutus-nav p{
     display: inline-block;
     margin-left:  0.5em;
     white-space: nowrap;
     font-size: 20px;
    } 
    .aboutus-navEn p{
        margin: 0;
margin-right: 0.5em;


    }
.aboutus-body {
    padding: 0;
}

    .aboutus-nav {
       border-left: none;
       border-right: none;
       height: auto;
       padding: 0;
       padding-bottom: 1.5em;
       margin-bottom: 1em;
       display: flex;
       flex-direction: row;
     
       overflow-x: auto;
       } 
     
    .aboutus{
flex-direction: column;
    }
    .scroller{
        display: flex;
    overflow-x: auto;
    flex-direction: row;
    height: auto;
    }
    .scroller .aboutusImg{
        min-width: 206px;

    }
    .scrollNews .col {
        margin-left: 2em;
        border-bottom: none;
    }
    .scrollNewsEn .col{
        margin-left: auto !important;
        margin-right: 2em;
    }
    .scrollNews{
        border: none;
    }
}





@media screen and (min-width: 1700px){
/*
 
.bodyTitle p{
    font-size: 1.5vw ;
      } 
      .bodyTitle div p{
        font-size: 1.2vw ;
        line-height: normal ;
     
      }
.aboutUs-title{
    font-size: 1.5vw ;

}
.abstrac-p1{
    font-size: 1.2vw ;
    line-height: normal;

}
.aboutus-nav p{
    font-size: 1.4vw ;

}
.aboutus-nav{
    height: 17vw;
}
.history-details{
    font-size: 1.2vw ;
 
}
.imgCaption p{
    font-size: 1.4vw ;

}
.imgCaption{
    padding: 2.5em 2em;
}
.backBtn button{
    font-size: 1.6vw;
    padding: 0.5em 1em;
}
.newsTitle{
    font-size: 2.5vw; 
}
.scrollNewsTitle{
    font-size: 1.6vw; 
}
.scrollTitle{
    font-size: 1.4vw; 
}
.newsDate{
    font-size: 1.5vw;  
}
.newsDescr{
    font-size: 1.5vw;     
}
*/
    }