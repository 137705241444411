.donation-form-container {
    max-width: 600px; /* Increase the width of the form if needed */
  }
.form-right {
    display: flex;
    justify-content: flex-end;
  }
  
  .form-left {
    display: flex;
    justify-content: flex-start;
  }

  .input-group label {
      font-family: 'cairo';
  }
  
  
  .donation-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-title {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .input-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
    text-align: right; 
  }
  
  .input-group input {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid gray;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .input-rtl input,
  .input-rtl label {
    text-align: right;
  }
  
  .input-ltr input,
  .input-ltr label {
    text-align: left;
  }
  
  .input-group input:focus {
    border-color: yellow; ;
    box-shadow: none;
    outline: 0;
  }
  .input-group input:active {
    border-color: gray;
  }
  
  .form-actions {
    text-align: center;
    margin-top: 2rem;
  }
  
  .submit-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: #40a9ff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #69c0ff;
  }
  
  .submit-button:active {
    background-color: #1890ff;
  }
  
.responsive-image {
  width: 550px; 
}

@media (max-width: 768px) {
  .responsive-image {
    width: 170px; 
    display: flex;
    
  }
}
