
.loginContainer{
  
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3em 0;
}
.login{

    background: #F4F4F4;
border-radius: 12px;
padding: 2em;
overflow: hidden;
width: 100%;
min-height: 56vh !important;
}
#otp div{
    justify-content: end;
    direction: ltr !important;
}

.eye{
    position: absolute;
    left: 10px;
    top: 8px;
    cursor: pointer;
    
    }
    .eyeEn{
        position: absolute;
        right: 10px;
        top: 8px;
        cursor: pointer;
        
        }
    .loginTitle{
        font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
    letter-spacing: -0.3px;
    text-align: center;
    color: #420F12;
    
    }
    .loginForm label{
    
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
     
        letter-spacing: -0.3px;
        
        color: #4F4F4F;
        display: block;
    margin-top: 1em;
    margin-bottom: 0.5em;
    }
    
    .loginForm input{
    
        background: #FFFFFF;
    border-radius: 8px;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    
    letter-spacing: -0.3px;
    
    color: #ACACAC;
    border: none;
    padding: 0.5em 10px;
    width: 80%;
    }
    .loginForm select{
    
        background: #FFFFFF;
    border-radius: 8px;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    
    letter-spacing: -0.3px;
    
    color: #ACACAC;
    border: none;
    padding: 0.5em 10px;
    width: 80%;
    }
    
    select:focus{
    
        border: 2px solid #E47100 !important;
        outline: none !important;
    }
    input:focus{
    
        border: 2px solid #E47100 !important;
        outline: none !important;
    }
    .loginForm .forgot{
        font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 37px;
    
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    
    color: #4F4F4F;
    cursor: pointer;
    
    position: absolute;
    right: 0;
    top: 2.8em;
    }
    .loginForm .forgot:hover{
    color: #E47100 ;
    
    }
    
    .loginForm button[type="submit"]{
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 37px;
     
        letter-spacing: -0.3px;
        padding: 0.3em 10px;
    width: 80%;
        background-color: #e47100;
    color: white;
    border: none;
    border-radius: 8px;
    }
    .loginForm button[type="submit"]:hover{
        background-color: #f28211;
     
    }
  
    .loginForm  .react-datepicker-wrapper input{
      width: 100%;
     
    }
    .loginForm .react-datepicker button{
      margin-top: 10px !important;
    }
.foodModal, .modalOverlay {
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 11;
}

.modalOverlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 1em;
    border-radius: 18px;
  
    z-index: 12;

   display: unset;
width: 500px;
height: 571px;
}
.vCenter{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.modal-content img{
width: 100%;
    max-width: 300px;
    height: 300px;
}
.close-modal {
    position: absolute;
    top: -16px;
    right: -6px;
  border: none;
  background: white;
  border-radius: 50%;
  padding: 0 0;
  color: #e47100;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.close-modal:hover {
    color: #f88f25;

}
.loginForm span{
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 37px;
    color: rgb(193, 22, 22);
    display: block;
}
.invalid-date input{
  border: 2px solid rgb(193, 22, 22) !important;

}
.react-datepicker__header__dropdown--scroll .react-datepicker__year-read-view--selected-year{
  color:  black;
}
.react-datepicker__header__dropdown--scroll .react-datepicker__year-read-view--down-arrow{

top: 35% !important;
}
.userData .inputDiv .react-datepicker__header__dropdown--scroll .react-datepicker__year-read-view--down-arrow{
  top: 20% !important;

}
.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 0%;
  left: 45%;
  margin: auto;
}

 .react-datepicker__year-option--selected_year{
font-weight: bold !important;
}
.react-datepicker__year-option--selected{
  display: none !important;
}
.react-datepicker__year-dropdown::after {
  content: "\25BC";
  display: block;
  position: relative;
  bottom: 0%;
}
.invalid {

    border: 2px solid rgb(193, 22, 22) !important;
}

.invalid:focus {

    border: 2px solid rgb(193, 22, 22) !important;
    outline: none !important;
}

.modalBody{
    display: flex;
    justify-content: space-between;
    margin: 1em 0;
    }

    .register{
        margin-top: 1em;
    }
    
    .register p{
    
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 37px;
      
        letter-spacing: -0.3px;
        
        color: #4F4F4F;
    margin-bottom: 0.2em;
    }
    
    
    .register button{
    
        font-family: 'Cairo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 37px;
     
        letter-spacing: -0.3px;
        padding: 0.3em 10px;
    width: 80%;
    border: 1px solid #E47100;
        background-color: white;
    color: #E47100;
    
    border-radius: 8px;
    
    }
    
    .register button:hover{
        color: white;
        background-color: #E47100;
    
    }
.alignOtp div{
  justify-content: start !important;
}
    .loginImagesAr{
        position: relative;
    }
    
    .loginImagesAr .img1{
    
    position: absolute;
    width: 300px;
   
    left:-21px;
    top:-72px;
    }
    .loginImagesAr .img2{
    
        position: absolute;
        width: 480px;
        left: -90px;
        }
        .loginImagesAr .img3{
    
            position: absolute;
            width: 115px;
            left: 11px;
            top: 61px;
            }
            input[type="checkbox"]{
                accent-color: #e47100;
                width: auto;
                cursor: pointer;
            }
            
            .terms{
               display: inline-block !important;
               margin:0 1em;
            
               cursor: pointer;
               font-family: 'Cairo';
font-style: normal;
font-weight: 400 !important;
font-size: 18px !important;
line-height: 34px;

letter-spacing: -0.3px;

color: #4F4F4F;
            }

            .forgot{

                font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 45px;
letter-spacing: -0.3px;

color: #4F4F4F;
margin-bottom: 0;
            }

            .dontWorry{

font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 37px;

letter-spacing: -0.3px;
margin-bottom: 0;
color: #4F4F4F;
            }

            .passForm input::placeholder{

font-family: 'Cairo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 30px;
letter-spacing: -0.3px;

color: #ACACAC;
            }

            .passForm label{
                font-family: 'Cairo';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 41px;
        
                letter-spacing: -0.3px;
                
                color: #4F4F4F;
                display: block;
                margin-top: 3em;
                margin-bottom: 0.5em;
                            }

                            .passForm input{
                                border: none;
                                background: #FFFFFF;
border-radius: 8px;
width: 80%;
padding: 0.5em 0.5em;
                            }
            .passAlign{
                text-align: center;

            
            }

            .passAlign img{

             margin-bottom: 0.8em;
            }
            .passAlignImg{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .passForm button{
                background: #E47100;
border-radius: 8px;
font-family: 'Cairo';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 37px;

letter-spacing: -0.3px;

color: #FFFFFF;
border: none;
display: block;
padding: 0.3em 0;
width: 80%;
margin-top: 2em;
            }
            .passForm button:hover{
                background: #f58311;

            }
            .passForm .formBTn{
                display: flex;
                justify-content: center;
            }
.passForm span{

    color: #c11616;
    display: block;
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
}
.loginPass{

    position:relative;
    width:80%;
    display:inline-block;
}
@media screen and (max-width: 922px){
   
    .loginImagesAr{ 
        display: none;

    }
    .loginContainer{

        padding: 2em 1em;
     
        padding-top: 5em;
    }
  
}

@media screen and (max-width: 500px){
   
    .loginTitle{ 
        font-size: 16px;

    }
    .loginForm label{
        font-size: 14px !important;
    }
    .loginForm input{
        font-size: 13px;
    }
    .loginForm button{
        font-size: 14px;
    }
    .register button{
        font-size: 14px;
    }
    .loginForm span{
        font-size: 12px;
    }
  .loginForm .forgot{
    font-size: 13px;
  }
  .loginForm select{
    font-size: 13px;
  }
  .eye{
      top: 6px !important;
  }
  .eyeEn{
    top: 6px !important;
}
  .register p{
    font-size: 13px;
  }
  .forgot{
    font-size: 16px;
  }
  .dontWorry{
    font-size: 14px;
  }
  .passForm label{
    font-size: 14px;
  }
  .passForm button{
    font-size: 14px;
  }
  .otpForm label{
    font-size: 14px !important;
  }
  .otpForm button{
    font-size: 14px !important;
  }
  .resendChangePass{
    font-size: 13px !important;
  }
  .invalidOtp{
    font-size: 12px !important; 
  }
}

@media screen and (min-width: 1700px){/*

    .loginTitle{ 
        font-size: 1.8vw;

    }
    .loginForm label{
        font-size: 1.7vw !important;
    }
    .loginForm input{
        font-size: 1.3vw
    }
    .loginForm button{
        font-size: 1.4vw
    }
    .register button{
        font-size: 1.4vw
    }
    .loginForm span{
        font-size: 1vw
    }
  .loginForm .forgot{
    font-size: 1.3vw
  }
  .loginForm select{
    font-size: 1.3vw
  }
  .eye{
      top: 6px !important;
      width: 2.2vw;
      height: 2.2vw;
  }
  .eyeEn{
    top: 6px !important;
}
  .register p{
    font-size: 1.3vw
  }
  .loginImagesAr{
transform: scale(1.3)  translateX(6em)  translateY(2em) !important; 

  }
  .loginImages{
    transform: scale(1.3)  translateX(-6em) translateY(2em) !important; 
}
  .forgot{
    font-size: 1.3vw
  }
  .dontWorry{
    font-size: 1.4vw
  }
  .passForm label{
    font-size: 1.4vw
  }
  .passForm button{
    font-size: 1.4vw
  }
  .otpForm label{
    font-size: 1.4vw !important;
  }
  .otpForm button{
    font-size: 1.4vw!important;
  }
  .resendChangePass{
    font-size: 1.3vw!important;
  }
  .invalidOtp{
    font-size: 1.2vw !important; 
  }
  .passAlign input{
    font-size: 1.4vw !important;
  }
  .passAlign input::placeholder{
    font-size: 1.4vw !important;
  }
  .otpPhone{
    font-size: 1.3vw !important
  }

  .otpImage{
      width: 5vw;
      height: 5vw;
      border-radius: 50% !important;
  }
  .otpForm button{
   padding: 0.5em 0;
  }
*/
}